import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Card, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { api } from '../../../services/apiClient';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa o locale português
import styles from './style.module.scss';

const { Title, Text } = Typography;

const FluxoDiario = () => {
  const [fluxoCaixa, setFluxoCaixa] = useState({ entradas: [], saidas: [] });
  const [entradas, setEntradas] = useState(0);
  const [saidas, setSaidas] = useState(0);

  useEffect(() => {
    moment.locale('pt-br'); // Configura o locale para português
    fetchFluxoCaixa();
  }, []);

  const fetchFluxoCaixa = async () => {
    try {
      const response = await api.get('/entradas-saidas');
      const { entradas, saidas } = response.data;
      setFluxoCaixa({ entradas: entradas || [], saidas: saidas || [] });
      calculateTotals(entradas, saidas);
    } catch (error) {
      console.error('Erro ao carregar dados do fluxo de caixa.', error);
    }
  };

  const calculateTotals = (entradas, saidas) => {
    const totalEntradas = (entradas || []).reduce((acc, curr) => acc + curr.valorPago, 0);
    const totalSaidas = (saidas || []).reduce((acc, curr) => acc + curr.valor, 0);
    setEntradas(totalEntradas);
    setSaidas(totalSaidas);
  };
  
  const combineAndGroupByDate = (entradas, saidas) => {
    const combinedData = [...entradas, ...saidas];
    return combinedData.reduce((acc, item) => {
      const dateKey = item.updatedAt || item.createdAt; // Use either updatedAt or createdAt
      const date = moment(dateKey).utc().format('YYYY-MM-DD'); // Garantir o uso do UTC
      if (!acc[date]) acc[date] = { entradas: [], saidas: [] };

      if (item.valorPago) {
        acc[date].entradas.push(item);
      } else {
        acc[date].saidas.push(item);
      }

      return acc;
    }, {});
  };

  const formatNumber = (number) => {
    return isNaN(number) ? 'R$ 0,00' : number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const renderGroupedData = (groupedData) => {
    return Object.keys(groupedData).map((date) => {
      const { entradas, saidas } = groupedData[date];
      const totalEntradasDoDia = entradas.reduce((acc, curr) => acc + curr.valorPago, 0);
      const totalSaidasDoDia = saidas.reduce((acc, curr) => acc + curr.valor, 0);

      return (
        <div key={date}>
          <div className={styles.dateHeader}>
            <Title level={4}>{moment(date).format('dddd, DD [de] MMM YYYY')}</Title>
            <Text className={styles.saldoDia}>Saldo do dia: <span>{formatNumber(totalEntradasDoDia - totalSaidasDoDia)}</span></Text>
          </div>
          <div className={styles.entrada}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
              <path d="M19.6665 18.6667C15.8832 17.6833 14.6665 16.6667 14.6665 15.0833C14.6665 13.2667 16.3499 12 19.1665 12C22.1332 12 23.2332 13.4167 23.3332 15.5H27.0165C26.8999 12.6333 25.1499 10 21.6665 9.15V5.5H16.6665V9.1C13.4332 9.8 10.8332 11.9 10.8332 15.1167C10.8332 18.9667 14.0165 20.8833 18.6665 22C22.8332 23 23.6665 24.4667 23.6665 26.0167C23.6665 27.1667 22.8499 29 19.1665 29C15.7332 29 14.3832 27.4667 14.1999 25.5H10.5332C10.7332 29.15 13.4665 31.2 16.6665 31.8833V35.5H21.6665V31.9167C24.9165 31.3 27.4999 29.4167 27.4999 26C27.4999 21.2667 23.4499 19.65 19.6665 18.6667Z" fill="#141D45" />
            </svg>
            <div>
              <Text className={styles.titulo}>Entradas</Text>
              <Text className={styles.valor}>{formatNumber(totalEntradasDoDia)}</Text>
            </div>
          </div>
          <div className={styles.saida}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
              <path d="M19.6665 18.6667C15.8832 17.6833 14.6665 16.6667 14.6665 15.0833C14.6665 13.2667 16.3499 12 19.1665 12C22.1332 12 23.2332 13.4167 23.3332 15.5H27.0165C26.8999 12.6333 25.1499 10 21.6665 9.15V5.5H16.6665V9.1C13.4332 9.8 10.8332 11.9 10.8332 15.1167C10.8332 18.9667 14.0165 20.8833 18.6665 22C22.8332 23 23.6665 24.4667 23.6665 26.0167C23.6665 27.1667 22.8499 29 19.1665 29C15.7332 29 14.3832 27.4667 14.1999 25.5H10.5332C10.7332 29.15 13.4665 31.2 16.6665 31.8833V35.5H21.6665V31.9167C24.9165 31.3 27.4999 29.4167 27.4999 26C27.4999 21.2667 23.4499 19.65 19.6665 18.6667Z" fill="#141D45" />
            </svg>
            <div>
              <Text className={styles.titulo}>Saídas</Text>
              <Text className={styles.valor}>{formatNumber(totalSaidasDoDia)}</Text>
            </div>
          </div>
        </div>
      );
    });
  };

  const groupedData = combineAndGroupByDate(fluxoCaixa.entradas, fluxoCaixa.saidas);

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            // href: '',
            title: (
              <>
                <span>Financeiro</span>
              </>
            ),
          },
          {
            title: 'Fluxo de Caixa',
          },
        ]}
      />
      <Row justify="center" gutter={[16, 16]}>
        <Col span={8}>

          <Link to='/financeiro/fluxodecaixa'>
            <Button type="primary" icon={<PlusOutlined />}>Adicionar</Button>
          </Link>
        </Col>
        <Col span={8}>
          <Card className={styles.card}>
            <div className={styles.icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M8.00016 4.16675V5.83341H13.4918L3.8335 15.4917L5.0085 16.6667L14.6668 7.00841V12.5001H16.3335V4.16675H8.00016Z" fill="#141D45" />
              </svg>
            </div>
            <Title level={4} className={styles.title}>{formatNumber(entradas)}</Title>
            <Text className={styles.text}>Total de Entradas</Text>
          </Card>
        </Col>
        <Col span={8}>
          <Card className={styles.card}>
            <div className={styles.icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M16.8335 4.50825L15.6585 3.33325L6.00016 12.9916V7.49992H4.3335V15.8333H12.6668V14.1666H7.17516L16.8335 4.50825Z" fill="#141D45" />
              </svg>
            </div>
            <Title level={4} className={styles.title}>{formatNumber(saidas)}</Title>
            <Text className={styles.text}>Total de Saídas</Text>
          </Card>
        </Col>
      </Row>
      <div className={styles.filtro}>
        <h2>Saldo em conta</h2>
        <h1>{formatNumber(entradas - saidas)}</h1>
      </div>
      <div className={styles.fluxoList}>
      {renderGroupedData(groupedData)}
      </div>
    </div>
  );
};

export default FluxoDiario;
