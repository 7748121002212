import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal } from 'antd';
import { api } from '../../services/apiClient';
import EditFluxoCaixa from '../EditFluxoCaixa';
import styles from './style.module.scss';
import moment from 'moment';

const ViewFluxoCaixa = ({ searchText, filterCategory, filterDate }) => {
  const [fluxoCaixa, setFluxoCaixa] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const fetchFluxoCaixa = async () => {
    try {
      const response = await api.get('/fluxo-de-caixa');
      setFluxoCaixa(response.data);
    } catch (error) {
      message.error('Erro ao carregar dados do fluxo de caixa.');
    }
  };

  useEffect(() => {
    fetchFluxoCaixa();
  }, []);

  const handleEdit = (id) => {
    setEditingId(id);
    setIsEditModalVisible(true);
  };

  const handleEditModalClose = () => {
    setEditingId(null);
    setIsEditModalVisible(false);
    fetchFluxoCaixa(); // Refresh the data after editing
  };

  const filteredData = fluxoCaixa.filter(item => {
    const matchesSearchText = 
      item.tipo.toLowerCase().includes(searchText.toLowerCase()) ||
      item.subtipo.toLowerCase().includes(searchText.toLowerCase()) ||
      item.categoria.toLowerCase().includes(searchText.toLowerCase());
    
    const matchesCategory = filterCategory ? item.categoria === filterCategory : true;
    
    const matchesDate = filterDate ? moment(item.createdAt).isSame(filterDate, 'day') : true;

    return matchesSearchText && matchesCategory && matchesDate;
  });

  const columns = [
    { title: 'Tipo', dataIndex: 'tipo', key: 'tipo' },
    { title: 'Subtipo', dataIndex: 'subtipo', key: 'subtipo' },
    { title: 'Valor', dataIndex: 'valor', key: 'valor' },
    { title: 'Categoria', dataIndex: 'categoria', key: 'categoria' },
    { title: 'Data', dataIndex: 'createdAt', key: 'createdAt', render: (text) => moment(text).format('DD/MM/YYYY') },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => handleEdit(record.id)} type='primary'>
          Editar
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Table columns={columns} dataSource={filteredData} rowKey='id' />
      <Modal
        title='Editar Fluxo de Caixa'
        visible={isEditModalVisible}
        onCancel={handleEditModalClose}
        footer={null}
      >
        {editingId && <EditFluxoCaixa id={editingId} onClose={handleEditModalClose} />}
      </Modal>
    </div>
  );
};

export default ViewFluxoCaixa;
