import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Row, Col, message, Button } from 'antd';
import styles from './style.module.scss';
import axios from 'axios';

import { api } from '../../services/apiClient';


const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const ModalPix = ({ modalPix, setModalPix, modalPixContent, fetchOperacoes }) => {
  const [dadosBancarios, setDadosBancarios] = useState('');

  const confirmarPagamento = async () => {
    const confirmed = await showConfirmModal();
    if (confirmed) {
      // Se o usuário confirmou o pagamento, atualize a etapa para "Pago"
      await atualizarEtapaParaPago();
    }
  };

  const showConfirmModal = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: 'Confirmar Pagamento',
        content: 'Tem certeza de que deseja confirmar o pagamento?',
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });
  };

  const atualizarEtapaParaPago = async () => {
    try {
      const response = await api.put(`/operation/${modalPixContent?.id}`, {
        etapa: 'aberto',
      });
      fetchOperacoes()
      message.success('Etapa atualizada para "Pago" com sucesso!');
      setModalPix(false);
    } catch (error) {
      console.error('Erro ao atualizar a etapa para "Pago":', error.message);
    }
  };

  useEffect(() => {
    if (modalPixContent?.cliente.bancoSelecionado) {
      buscarBanco(modalPixContent?.cliente.bancoSelecionado);
    }
  }, [modalPixContent?.cliente.bancoSelecionado]);


  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const formatarCPF = (cpf) => {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const [bancos, setBancos] = useState('');

  const buscarBanco = async (id) => {
    try {
      const resposta = await axios.get(`https://brasilapi.com.br/api/banks/v1/${id}`);
      setBancos(resposta.data);
    } catch (erro) {
      console.error('Não foi possível carregar os bancos:', erro);
    }
  };
  useEffect(() => {
    if (modalPixContent?.cliente.bancoSelecionado) {
      buscarBanco(modalPixContent?.cliente.bancoSelecionado);
    }
  }, [modalPixContent?.cliente.bancoSelecionado]);


  return (
    <>
      <Modal title='Pix' open={modalPix} onCancel={() => setModalPix(false)} footer={null}>
        <Row gutter={[16, 16]} className={styles.containerValorPix}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='Valor'>
              <Input disabled value={formatarValor(modalPixContent?.valorTotal)} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='Chave Pix'>
              <Input disabled value={modalPixContent?.cliente.chavePix} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='CPF'>
              <Input disabled value={formatarCPF(modalPixContent?.cliente.cpf)} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='Nome favorecido'>
              <Input disabled value={modalPixContent?.cliente.nomeFavorecido} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='Banco'>
              <Input disabled value={bancos.name || ''} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.containerButtons}>
          <Button type="primary" onClick={confirmarPagamento}>Confirmar Pagamento</Button>
          <Button onClick={() => setModalPix(false)}>Cancelar</Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalPix;
