import React, { useEffect, useState } from 'react';
import { api } from '../../services/apiClient';
import { Button, Form, Input, Row, Col, Select, Spin } from 'antd';
import styles from './style.module.scss';

import { Bar, Pie, Line } from 'react-chartjs-2';
import TableColabores from '../TableColabores';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Tooltip, Legend, Filler } from 'chart.js';
import { Divider } from '@mui/material';

// Registering the necessary ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
  Filler // Add this line for Area charts
);

const Graphs = ({ dataTabeColab, onChildUpdate, progress }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchSubOperacoes = async () => {
      try {
        const response = await api.get('/dashboard');
        setData(response.data);
      } catch (error) {
        setData([]);
        console.error('Failed to fetch data:', error);
      }
    };

    fetchSubOperacoes();
  }, []);

  if (!data) {
    return (
      <div className={styles.spinner}>
        <Spin />
      </div>
    );
  }

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const operationsByDayData = {
    labels: data.operationsCountByDay
      .filter((item) => {
        const date = new Date(item.dataEmprestimo);
        return date.getFullYear() === currentYear && date.getMonth() === currentMonth;
      })
      .map((item) => item.dataEmprestimo.split('T')[0]),
    datasets: [
      {
        label: 'Número de operações',
        data: data.operationsCountByDay
          .filter((item) => {
            const date = new Date(item.dataEmprestimo);
            return date.getFullYear() === currentYear && date.getMonth() === currentMonth;
          })
          .map((item) => item._count),
        fill: true,
        backgroundColor: '#3E4666',
        borderColor: '#141D45',
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const operationsCountByMonthData = {
    labels: data.operationsCountByMonthArray.filter((item) => item.year === currentYear).map((item) => `${item.month}/${item.year}`),
    datasets: [
      {
        label: 'Contagem de Operações por Mês',
        data: data.operationsCountByMonthArray.filter((item) => item.year === currentYear).map((item) => item.count),
        fill: true,
        backgroundColor: '#3E4666',
        borderColor: '#141D45',
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const operationsStatusData = {
    labels: data.operationsStatusCounts.map((item) => item.etapa),
    datasets: [
      {
        data: data.operationsStatusCounts.map((item) => item._count),
        backgroundColor: ['#141D45', '#3E4666', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
        borderColor: ['#141D45', '#3E4666', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 2,
      },
    ],
  };

  const operationTypesData = {
    labels: data.operationsTipo.map((item) => item.tipoOperacao),
    datasets: [
      {
        label: 'Valor dos Empréstimos',
        data: data.operationsTipo.map((item) => item._sum.valorEmprestimo),
        backgroundColor: '#3E4666',
        borderColor: '#141D45',
        hoverBorderWidth: 3,
      },
    ],
  };

  const chartData = {
    labels: data.usuariosPorOperacao.map((item) => item.usuarioNome),
    datasets: [
      {
        data: data.usuariosPorOperacao.map((item) => item._sum.valorEmprestimo),
        backgroundColor: ['#6B718A', '#0B1127', '#3E4666', '#11193b', '#9a9eaf', '#6b718a', '#141D45'],
        borderColor: ['#6B718A', '#0B1127', '#3E4666', '#11193b', '#9a9eaf', '#6b718a', '#141D45'],
        hoverBorderWidth: 3,
      },
    ],
  };

  const areaChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: '#ECECEC',
        },
        ticks: {
          color: '#4F4F4F',
          font: {
            size: 12,
          },
        },
      },
      x: {
        grid: {
          color: '#ECECEC',
        },
        ticks: {
          color: '#4F4F4F',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '4F4F4F',
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        footerColor: '#fff',
      },
    },
  };

  const pieChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: '#4F4F4F',
          font: {
            size: 16,
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        footerColor: '#fff',
      },
    },
  };

  const barChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: '#ECECEC',
        },
        ticks: {
          color: '#4F4F4F',
          font: {
            size: 12,
          },
        },
      },
      x: {
        grid: {
          color: '#ECECEC',
        },
        ticks: {
          color: '#4F4F4F',
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#4F4F4F',
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        footerColor: '#fff',
      },
    },
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={12} lg={24}>
          <div className={styles.containerGraficos}>
            <h2>Operações por Dia</h2>
            <Divider></Divider>
            <div className={styles.mes}>
              <Line data={operationsByDayData} options={areaChartOptions} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className={styles.containerGraficos}>
            <h2>Operações por Mês</h2>
            <Divider></Divider>
            <div className={styles.mes}>
              <Line data={operationsCountByMonthData} options={areaChartOptions} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className={styles.containerGraficos}>
            <h2>Tipos de Operações</h2>
            <Divider></Divider>
            <div className={styles.mes}>
              <Bar data={operationTypesData} options={barChartOptions} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.containerGraficos}>
            <h2>Status das Operações</h2>
            <Divider></Divider>
            <div className={styles.status}>
              <Pie data={operationsStatusData} options={pieChartOptions} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className={styles.containerGraficos}>
            <h2>Colaboradores</h2>
            <Divider></Divider>
            <div className={styles.status}>
              <Pie data={chartData} options={pieChartOptions} />
            </div>
          </div>
        </Col>
      </Row>

      {/* <TableColabores data={dataTabeColab} onChildUpdate={onChildUpdate} progress={progress} /> */}
    </div>
  );
};

export default Graphs;
