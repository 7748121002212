// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__weGgF {
  width: 100%;
  overflow-y: auto;
}

.style_table__cPArm {
  width: 100%;
  margin-top: 2rem;
}
.style_table__cPArm table {
  border: 1px solid #141D45;
  background: rgba(170, 150, 93, 0.2);
}
.style_table__cPArm table thead tr th {
  background-color: #141D45 !important;
  color: #000 !important;
  font-family: Inter;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  padding: 10px;
}

.style_buttonPagar__HVH1u {
  color: var(--Foundation-Dark-D0, #fff);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.style_botaoVer__qmRdJ {
  height: 3rem;
  cursor: pointer;
  gap: 0.5rem;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid rgba(42, 47, 56, 0.25);
  background: var(--foundation-purple-p-900-x, #AA965D);
}`, "",{"version":3,"sources":["webpack://./src/Components/TableConfirmado/style.module.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,gBAAA;AAAF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;AAAE;EACE,yBAAA;EACA,mCAAA;AAEJ;AACQ;EACE,oCAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;AACV;;AAMA;EACE,sCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,YAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,qBAAA;EACA,wCAAA;EACA,qDAAA;AAHF","sourcesContent":[".container {\n  // background-color: #AA965D;\n  width: 100%;\n  overflow-y: auto;\n}\n.table {\n  width: 100%;\n  margin-top: 2rem;\n  table {\n    border: 1px solid #141D45;\n    background: rgba(170, 150, 93, 0.2);\n    thead {\n      tr {\n        th {\n          background-color: #141D45 !important;\n          color: #000 !important;\n          font-family: Inter;\n          text-overflow: ellipsis;\n          font-size: 1rem;\n          font-style: normal;\n          font-weight: 600;\n          padding: 10px;\n        }\n      }\n    }\n  }\n}\n\n.buttonPagar {\n  color: var(--Foundation-Dark-D0, #fff);\n  font-family: Inter;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 600;\n  padding: 1rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.botaoVer {\n  height: 3rem;\n  cursor: pointer;\n  gap: 0.5rem;\n  font-weight: 900;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n  border-radius: 0.5rem;\n  border: 1px solid rgba(42, 47, 56, 0.25);\n  background: var(--foundation-purple-p-900-x, #AA965D);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__weGgF`,
	"table": `style_table__cPArm`,
	"buttonPagar": `style_buttonPagar__HVH1u`,
	"botaoVer": `style_botaoVer__qmRdJ`
};
export default ___CSS_LOADER_EXPORT___;
