// CreateFluxoCaixa.js
import React from 'react';
import { Form, Input, Button, message, InputNumber, Select } from 'antd';
import { api } from '../../services/apiClient';

const { Option } = Select;

const CreateFluxoCaixa = ({ onClose }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      await api.post('/fluxo-de-caixa', values);
      message.success('Fluxo de caixa criado com sucesso!');
      form.resetFields();
      onClose();
    } catch (error) {
      message.error('Erro ao criar fluxo de caixa.');
    }
  };

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Form.Item name='tipo' label='Nome' rules={[{ required: true }]} style={{ marginTop: '1rem' }}>
        <Input />
      </Form.Item>
      <Form.Item name='subtipo' label='Descrição' style={{ marginTop: '1rem' }}>
        <Input />
      </Form.Item>
      <Form.Item
        name='valor'
        label='Valor'
        rules={[{ required: true }]}
        style={{ marginTop: '1rem' }}
      >
        <Input
          style={{ width: '100%' }}
          placeholder='Informe o valor'
          addonBefore='R$'
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          }
          parser={(value) => value.replace(/\R\$\s?|(,*)/g, '').replace('.', ',')}
        />
      </Form.Item>

      <Form.Item name='categoria' label='Categoria' rules={[{ required: true }]} style={{ marginTop: '1rem' }}>
        <Select placeholder='Selecione uma categoria'>
          <Option value='Empréstimos'>Empréstimos</Option>
          <Option value='Pagamento Comissão'>Pagamento Comissão</Option>
          <Option value='Retirada sócio'>Retirada sócio</Option>
          <Option value='Despesas bancárias'>Despesas bancárias</Option>
          <Option value='Outras saídas'>Outras saídas</Option>
          <Option value='Devolução de aporte'>Devolução de aporte</Option>
          <Option value='Salário'>Salário</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' style={{ marginTop: '2rem' }}>
          Criar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateFluxoCaixa;
