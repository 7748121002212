import React, { useState, useMemo } from 'react';
import { Modal, Image, Row, Col, Form, Input } from 'antd';
import styles from './style.module.scss';

const ModalSeeClients = ({ clienteSelecionado, isEditable, isModalOpenComprovantes, setIsModalOpenComprovantes, fotosDocumento, fotosSelfieDocumento, fotosComprovante }) => {
  return (
    <Modal width={900} title='Documentos' open={isModalOpenComprovantes} onOk={() => setIsModalOpenComprovantes(false)} onCancel={() => setIsModalOpenComprovantes(false)}>
      <div className={styles.containerImages}>
        <h2 className={styles.tituloImages}>Dados do Cliente</h2>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item label='Nome' className={styles.containerValorPix}>
              <Input disabled={!isEditable} value={clienteSelecionado?.nome} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='CPF'>
              <Input disabled={!isEditable} value={clienteSelecionado?.cpf} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='RG'>
              <Input disabled={!isEditable} value={clienteSelecionado?.rg} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Celular'>
              <Input disabled={!isEditable} value={clienteSelecionado?.celular} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Apelido'>
              <Input disabled={!isEditable} value={clienteSelecionado?.apelido} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Apelido'>
              <Input disabled={!isEditable} value={clienteSelecionado?.dataNascimento} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Cep Comercial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.cepComercial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Bairro comercial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.bairroComercial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Cidade comercial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.cidadeComercial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Rua Comercial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.logradouroComercial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Numero Comercial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.numeroComercial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Bairro residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.bairroResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Cep residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.cepResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Cidade Residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.cidadeResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Estado Residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.estadoResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Rua residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.logradouroResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} className={styles.containerValorPix}>
            <Form.Item label='Numero residencial'>
              <Input disabled={!isEditable} value={clienteSelecionado?.numeroResidencial} readOnly className={styles.customDisabledInput} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.containerCliente}>
          <div>
            <h2 className={styles.tituloImages}>Fotos com documento</h2>
            <div>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                {fotosDocumento.map((foto, index) => (
                  <Image key={`doc-${index}`} width={200} src={foto} />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>
          <div>
            <h2 className={styles.tituloImages}>Selfie com documentos</h2>
            <div>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                {fotosSelfieDocumento.map((foto, index) => (
                  <Image key={`doc-${index}`} width={200} src={foto} />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>
          <div>
            <h2 className={styles.tituloImages}>Selfie com documentos</h2>
            <div>
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                {fotosComprovante.map((foto, index) => (
                  <Image key={`doc-${index}`} width={200} src={foto} />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSeeClients;
