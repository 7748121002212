import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import TableClientes from '../../../Components/TableClientes'

const Clientes = () => {
  const [clienteData, setClienteData] = useState([])


  const fetchData = async () => {
    try {
      const response = await api.get(`/client`);
      
      setClienteData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchData();
  }, []);



  return (
    <TableClientes data={clienteData} fetchData={fetchData}/>
  );
};

export default Clientes;