import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Form, Input, Select, DatePicker, Button, message, Row, Col } from 'antd';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { CiSearch } from 'react-icons/ci';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select; // Importação corrigida

const MetasCadastro = () => {
  const [colaborador, setColaborador] = useState('');
  const [tipoMeta, setTipoMeta] = useState('');
  const [tipoOperacao, setTipoOperacao] = useState('');
  const [valorTotal, setValorTotal] = useState('');

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = () => {
    setLoading(true);

    const dadosOperacao = {
      colaborador,
      tipoMeta,
      tipoOperacao,
      valorTotal,
    };

    setTimeout(() => {
      message.success('Dados enviados com sucesso!');
      setLoading(false);
    }, 5000);
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            // href: '',
            title: (
              <>
                <span>Configurações</span>
              </>
            ),
          },
          {
            title: 'Metas',
          },
        ]}
      />
      <div className={styles.containerCadastrar}>
        <h1>Cadastrar metas</h1>
        <div className={styles.row}>
          <Form layout='vertical' onFiniash={handleFormSubmit} className={styles.containerInput}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name='colaborador'
                  className={styles.formItem}
                  rules={[{ required: true, message: 'Por favor, selecione o colaborador!' }]}
                  label={
                    <span className={styles.labelWithIcon}>
                      {/* Ícone SVG */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                        <path d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#E8E8EC" />
                      </svg>
                      {/* Texto do label */}
                      <span className={styles.labelText}>Colaborador</span>
                    </span>
                  }
                >
                  <Select placeholder='Selecionar' onChange={(e) => setColaborador(e)}>
                    <Option value='joão'>joão</Option>
                    <Option value='marcos'>marcos</Option>
                    <Option value='alan'>alan</Option>
                    <Option value='vitor'>vitor</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name='tipoMeta'
                  rules={[{ required: true, message: 'Por favor, selecione o tipo da meta!' }]}
                  className={styles.formItem}
                  label={<span className={styles.labelWithIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                      <path d="M6.99969 6H16.9997L11.9897 12.3L6.99969 6ZM4.24969 5.61C6.26969 8.2 9.99969 13 9.99969 13V19C9.99969 19.55 10.4497 20 10.9997 20H12.9997C13.5497 20 13.9997 19.55 13.9997 19V13C13.9997 13 17.7197 8.2 19.7397 5.61C19.8543 5.46237 19.9252 5.28553 19.9442 5.09961C19.9632 4.91368 19.9296 4.72615 19.8473 4.55837C19.765 4.39059 19.6372 4.24929 19.4785 4.15058C19.3198 4.05186 19.1366 3.99969 18.9497 4H5.03969C4.20969 4 3.73969 4.95 4.24969 5.61Z" fill="#E8E8EC" />
                    </svg>
                    <span  className={styles.labelText}>Tipo de meta</span>
                  </span>}
                >
                  <Select placeholder='Selecionar' onChange={(e) => setTipoMeta(e)}>
                    <Option value='valor'>Valor</Option>
                    <Option value='operacao'>Operação</Option>
                  </Select>
                </Form.Item>
              </Col>
              {tipoMeta === 'operacao' ? (
                <>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Tipo de operação'>
                      <Select placeholder='Selecionar' value={tipoOperacao} onChange={setTipoOperacao}>
                        <Option value='diario'>Diário</Option>
                        <Option value='semanal'>Semanal</Option>
                        <Option value='mensal'>Mensal</Option>
                        <Option value='quinzenal'>Quinzenal</Option>
                        <Option value='avulso'>Avulso</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label='Valor Total'>
                      <CurrencyInput
                        id='valor-total-input'
                        name='valorTotal'
                        className={styles.valueInput}
                        placeholder='Valor calculado automaticamente'
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        decimalsLimit={2}
                        value={valorTotal}
                        disabled={false}
                        prefix='R$ '
                        onValueChange={(value, name) => setValorTotal(value)}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : tipoMeta === 'valor' ? (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item label='Valor Total'>
                    <CurrencyInput
                      id='valor-total-input'
                      name='valorTotal'
                      className={styles.valueInput}
                      placeholder='Valor calculado automaticamente'
                      intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                      decimalsLimit={2}
                      value={valorTotal}
                      disabled={false}
                      prefix='R$ '
                      onValueChange={(value, name) => setValorTotal(value)}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        </div>
        <button className={styles.buttonCadastrarOp} onClick={handleFormSubmit}>
          {loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 30,
                    color: '#fff',
                  }}
                  spin
                />
              }
            />
          ) : (
            'Cadastrar'
          )}
        </button>
      </div>
    </div>
  );
};

export default MetasCadastro;
