import React from 'react';
import styles from './style.module.scss';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const BoxDash = ({
  isRedirectOperation,
  type1 = null,
  quantidade = null,
  value = null,
  icon = null,
  type2 = null,
  subtype = null,
  onClick = null,
  color = 'black', // Default color
  borderColor = '#DDD',
  background = 'white', // Default background
}) => {
  const { user } = useAuth();
  console.log('user', user);

  return isRedirectOperation ? (
    <Link
      to='/cadastro/contratos'
      className={styles.containerOperacao}
      style={{ color, background, borderColor }} // Apply styles here
    >
      <p>+ Operação</p>
    </Link>
  ) : (
    <div style={{ display: 'block', flex: 1 }}>
      <div className={styles.containerDefault} style={{ color, background, borderColor }}>
        <div className={styles.containerUm}>
          <h2>{type1}</h2>
          <h1>{quantidade}</h1>
        </div>
        {user.tipoUsuario < 3 && (
          <>
            <Divider type='vertical' className={styles.divider} style={{ borderColor }} />
            <div className={styles.containerDois}>
              <h2>{type2}</h2>
              <h1>{value}</h1>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BoxDash;
