import React from 'react';
import { Spin, Popconfirm } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss';

const LoadingAmpulheta = () => {
  return (
    <div className={styles.hourglassBackground}>
      <div className={styles.hourglassContainer}>
        <div className={styles.hourglassCurves}></div>
        <div className={styles.hourglassCapTop}></div>
        <div className={styles.hourglassGlassTop}></div>
        <div className={styles.hourglassSand}></div>
        <div className={styles.hourglassSandStream}></div>
        <div className={styles.hourglassCapBottom}></div>
        <div className={styles.hourglassGlass}></div>
      </div>
    </div>
  );
};

export default LoadingAmpulheta;
