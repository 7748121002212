import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Dropdown, Spin, Modal, Pagination, Select, Button, message, Upload, Form, QRCode, Input, Col, Progress, Divider, Empty } from 'antd';
import styles from './style.module.scss';
import ModalParcelas from '../../Components/ModalParcelas';
import ModalMensal from '../../Components/ModalMensal';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { api } from '../../services/apiClient';
import { MdOutlineRemoveRedEye, MdOutlineDelete } from "react-icons/md";
import { AiOutlineFile } from "react-icons/ai";
import html2pdf from 'html2pdf.js';


const TableContratos = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25); // Alterado para número
  const [data, setDadosTabela] = useState([]);
  const [totaisPorStatus, setTotaisPorStatus] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const tableRef = useRef(null);

  const fetchOperacoes = async (page) => {

    try {
      const response = await api.get(`/operation?page=${page}&pageSize=${pageSize}`);
      const { operacoes, totaisPorStatus } = response.data;
      setDadosTabela((prevData) => [...operacoes]);
      setTotaisPorStatus(totaisPorStatus);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOperacoes(currentPage);
  }, [currentPage]);

  const handleScroll = () => {
    const table = tableRef.current;
    if (table) {
      const { scrollTop, scrollHeight, clientHeight } = table;
      if (scrollTop + clientHeight >= scrollHeight * 0.9) {
        // Carrega mais dados quando o usuário rolar até 90% do final da tabela
        setLoadingMore(true);
        setTimeout(() => {
          setCurrentPage((prevPage) => prevPage + 1); // Carrega a próxima página
          setLoadingMore(false);
        }, 1000); // Simulação de carga
      }
    }
  };


  const onChildUpdate = () => {
    fetchOperacoes();
  };

  const [isModalOpenAnotacoes, setIsModalOpenAnotacoes] = useState(false);
  const [modalContentAnotacoes, setModalContentAnotacoes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMensal, setIsModalOpenMensal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [novaEtapaChange, setNovaEtapaChange] = useState('');
  const [operacaoIdChange, setOperacaoIdChange] = useState(0);
  const { Option } = Select;
  const [operacaoIdParaUpload, setOperacaoIdParaUpload] = useState(null);
  const [isModalOpenComprovantes, setIsModalOpenComprovantes] = useState(false);
  const [valorPago, setValorPago] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    cliente: null,
    etapas: null,
    cpf: null,
    colaborador: null,
    operacao: null,
  });

  const uniqueClientes = [...new Set(data.map((item) => item.cliente.nome))];
  const uniqueCpfs = [...new Set(data.map((item) => item.cliente.cpf))];
  const uniqueColab = [...new Set(data.map((item) => item.usuario.nome))];

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data
      .filter((row) => {
        return (!activeFilters.cliente || row.cliente.nome === activeFilters.cliente) &&
          (!activeFilters.cpf || row.cliente.cpf === activeFilters.cpf) &&
          (!activeFilters.colaborador || row.usuario.nome === activeFilters.colaborador);
      })
      .slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, data, activeFilters]);




  const formatarComoMoeda = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor); // Divisão por 100 para corrigir o formato
  };

  const getEtapaID = (etapa) => {
    return `etapa-${etapa}`; // Isso retornará algo como "etapa-inicio" baseado na etapa.
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    if (isNaN(data.getTime())) {
      console.error('Data inválida:', dataString);
      return 'Data inválida'; // ou alguma outra ação de erro
    }
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      timeZone: 'UTC',
    }).format(data);
  };

  const abrirModalSubOperacoes = (subOperacoes) => {
    setModalContent(subOperacoes);
    setIsModalOpen(true);
  };
  const abrirModalMensal = (subOperacoes) => {
    setModalContent(subOperacoes);
    setIsModalOpenMensal(true);
  };
  const atualizarEtapaOperacao = async (id, novaEtapa, isOperacao) => {
    if ((novaEtapa === 'parcial' && !isOperacao) || (novaEtapa === 'finalizado' && !isOperacao)) {
      setNovaEtapaChange(novaEtapa);
      setOperacaoIdChange(id);
      setIsModalOpenComprovantes(true);
      setOperacaoIdParaUpload(id);
    } else {
      if (isOperacao) {
        try {
          const response = await api.put(`/operation/${id}`, { etapa: novaEtapa });
          message.success('Operação atualizada com sucesso!');
        } catch (error) {
          console.error('Erro ao atualizar a etapa da operação:', error);
        }
        onChildUpdate();
      } else {
        try {
          const response = await api.put(`/suboperation/${id}`, { status: novaEtapa });
        } catch (error) {
          console.error('Erro ao atualizar a etapa da suboperação:', error);
        }
      }
    }
  };

  const deletarOperacao = async (id) => {
    Modal.confirm({
      title: 'Tem certeza que deseja deletar esta operação?',
      content: 'Esta ação é irreversível e excluirá todos os dados relacionados à operação.',
      okText: 'Sim, deletar',
      okType: 'danger',
      cancelText: 'Não',
      onOk: async () => {
        try {
          await api.put(`/operation/${id}`, {
            deletedAt: new Date().toISOString(),
          });

          // Atualiza a lista data após a exclusão
          setDadosTabela((prevData) => prevData.filter((operacao) => operacao.id !== id));

          message.success('Operação deletada com sucesso!');
        } catch (error) {
          console.error('Erro ao deletar a operação:', error);
          message.error('Erro ao deletar a operação.');
        }
      }
    });
  };


  const uploadProps = {
    name: 'file',
    multiple: true,
    customRequest: async (options) => {
      const { onSuccess, onError, file } = options;
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await api.put(`/suboperation/${operacaoIdParaUpload}`, formData, {});
        message.success(`Upload de ${file.name} concluído com sucesso.`);
        onSuccess(response.data);
      } catch (error) {
        message.error(`Falha no upload de ${file.name}.`);
        onError(error);
      }
    },
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.data.Key;
    } catch (error) {
      console.error('Erro ao fazer upload de arquivo', error);
      throw error;
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [reciboVisible, setReciboVisible] = useState(false);
  const [reciboTexto, setReciboTexto] = useState('');

  const mostrarRecibo = (operacao) => {
    const recibo = formatarReciboHTML(operacao);
    setReciboTexto(recibo);
    setReciboVisible(true);
    setCelularEnvio(operacao.cliente.celular)
  };

  const formatarReciboHTML = (operacao) => {
    const { cliente, usuarioId, valorEmprestimo, valorTotal, juros, numeroParcelas, tipoOperacao, dataEmprestimo, proximaParcela, subOperacoes } = operacao;

    let recibo = `<p><strong>------- RECIBO DE OPERAÇÃO -------</strong></p>`;
    recibo += `<br/><p><strong>Dados do Cliente:</strong></p>`;
    recibo += `<p>Nome: <strong>${cliente.nome}</strong></p>`;
    recibo += `<p>CPF: <strong>${cliente.cpf}</strong></p>`;

    recibo += `<br/><p><strong>Detalhes da Operação:</strong></p>`;
    recibo += `Tipo: <strong>${tipoOperacao}</strong><br>`;
    recibo += `Valor Emprestado: <strong>${valorEmprestimo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong><br>`;
    recibo += `Total a Pagar: <strong>${valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong><br>`;
    recibo += `Número de Parcelas: <strong>${numeroParcelas}</strong><br>`;
    recibo += `Data do Empréstimo: <strong>${new Date(dataEmprestimo).toLocaleDateString('pt-BR')}</strong><br>`;
    recibo += `Próxima Parcela: <strong>${new Date(proximaParcela).toLocaleDateString('pt-BR')}</strong></p>`;

    recibo += `<br/><p><strong>Parcelas Detalhadas:</strong></p>`;
    subOperacoes.forEach((parcela, index) => {
      recibo += `  ${index + 1}. Valor: <strong>${parcela.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>`;
      recibo += ` - Vencimento: <strong>${new Date(parcela.dataVencimento).toLocaleDateString('pt-BR')}</strong>`;
      recibo += ` -<strong>${parcela.status}</strong><br>`;
    });

    recibo += `<br/><p>Atendido por: <strong>${usuarioId}</strong></p>`;
    recibo += `<br/><p><strong>-----------------------------------</strong></p>`;
    return recibo;
  };

  const [showFilters, setShowFilters] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [form] = Form.useForm();

  const handleEdit = (record) => {
    setEditFormData(record);
    form.setFieldsValue({
      etapa: record.etapa,
      tipoOperacao: record.tipoOperacao,
      valorEmprestimo: record.valorEmprestimo,
      prazo: record.prazo,
      dataEmprestimo: record.dataEmprestimo,
      proximaParcela: record.proximaParcela,
      valorTotal: record.valorTotal,
      numeroParcelas: record.numeroParcelas,
      juros: parseFloat(record.juros),
      statusPagamento: record.statusPagamento,
      verificarPagamento: record.verificarPagamento,
      anotacoes: record.anotacoes,
    });
    setIsEditModalOpen(true);
  };

  const updateOperacao = async (values) => {

    // Converter o valor de juros para número
    const jurosNumerico = Number(values.juros);

    try {
      const response = await api.put(`/operation/${editFormData.id}`, {

        ...values,
        juros: jurosNumerico, // Usando o valor numérico dos juros
        clienteId: editFormData.clienteId,
        usuarioId: editFormData.usuarioId,
      });
      message.success('Operação atualizada com sucesso!');
      setIsEditModalOpen(false);
      fetchOperacoes(); // Refresh the operations list
    } catch (error) {
      message.error('Erro ao atualizar a operação.');
    }
  };

  const handleFilterChange = (filterType, value) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const [celularEnvio, setCelularEnvio] = useState();

  async function enviarReciboWhatsApp() {
    const mensagem = "Aqui está o seu recibo.";

    try {
      await new Promise(resolve => setTimeout(resolve, 2000));

      const celularLimpo = celularEnvio.replace(/\D/g, '');
      const API_URL = 'https://api.whatsapp.com/send?phone=' + celularLimpo + '&text=' + encodeURIComponent(mensagem);

      const whatsappWindow = window.open(API_URL);

      if (!whatsappWindow || whatsappWindow.closed || typeof whatsappWindow.closed === 'undefined') {
        throw new Error('A janela do WhatsApp não pôde ser aberta.');
      }

      await new Promise(resolve => setTimeout(resolve, 2000));
    } catch (error) {
      console.error('Erro ao enviar recibo para', error);
      return;
    }

    try {
      const imprimirRecibo = () => {
        const newWindow = window.open('');

        newWindow.document.write(`<pre>${reciboTexto}</pre>`);
        newWindow.document.close();

        html2pdf().from(newWindow.document.body).save();

        newWindow.close();
      };

      imprimirRecibo();
    } catch (error) {

      const popupsHabilitados = window.confirm("Por favor, habilite os pop-ups para continuar.");

      if (!popupsHabilitados) {
        return;
      }

      console.error('Erro ao imprimir o recibo', error);
      return;
    }
  }

  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [selectedCpf, setSelectedCpf] = useState(null);
  const [selectedColaborador, setSelectedColaborador] = useState(null);

  const handleFiltrarClick = () => {
    setSearchBarVisible(!searchBarVisible);
  };


  const handleCancelarClick = () => {
    setSearchBarVisible(false);
    handleLimparFiltros()
  };

  const handleLimparFiltros = () => {
    setActiveFilters({
      cliente: null,
      etapas: null,
      cpf: null,
      usuario: null,
      operacao: null,
    });
    setSelectedCliente(null);
    setSelectedCpf(null);
    setSelectedColaborador(null);
    setCurrentPage(1);
  };

  const getTotalContracts = () => {
    return data.length; // Retorna o número total de itens na lista paginada
  };

  return (
    <div className={styles.container} onScroll={handleScroll} ref={tableRef}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to="/">
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            // href: '',
            title: (
              <>
                <span>Bases</span>
              </>
            ),
          },
          {
            title: 'Contratos',
          },
        ]}
      />
      <div className={styles.top}>
        <div className={styles.contractCounter}>
          <h1>Número de contratos: <span>{getTotalContracts()}</span></h1>
        </div>
        <div className={styles.filtro}>

          <Button onClick={handleFiltrarClick}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M4.66679 3.99984H11.3335L7.99345 8.19984L4.66679 3.99984ZM2.83345 3.73984C4.18012 5.46651 6.66679 8.6665 6.66679 8.6665V12.6665C6.66679 13.0332 6.96679 13.3332 7.33345 13.3332H8.66679C9.03345 13.3332 9.33345 13.0332 9.33345 12.6665V8.6665C9.33345 8.6665 11.8135 5.46651 13.1601 3.73984C13.2365 3.64142 13.2838 3.52352 13.2964 3.39957C13.3091 3.27563 13.2868 3.15061 13.2319 3.03875C13.177 2.9269 13.0918 2.8327 12.986 2.76689C12.8802 2.70108 12.758 2.6663 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z'
                fill='#FFFFFF'
              />
            </svg>{' '}
            Filtrar
          </Button>
        </div>
      </div>
      {searchBarVisible && (
        <div className={styles.searchBar}>
          <div className={styles.containerSearchBar}>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 24" fill="none">
                  <path d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#E8E8EC" />
                </svg>
                <p style={{ paddingLeft: '.5rem' }}>Pesquisar cliente</p>
              </div>
              <Select
                id='cliente-select'
                showSearch
                allowClear
                placeholder='Selecione o cliente'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('cliente', value);
                  setSelectedCliente(value); // Atualiza o estado selecionado
                }}
                value={selectedCliente} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueClientes.map((cliente) => (
                  <Option key={cliente} value={cliente}>
                    {cliente}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <p>CPF</p>
              </div>
              <Select
                id='cpf-select'
                showSearch
                allowClear
                placeholder='Selecione o CPF'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('cpf', value);
                  setSelectedCpf(value); // Atualiza o estado selecionado
                }}
                value={selectedCpf} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueCpfs.map((cpf) => (
                  <Option key={cpf} value={cpf}>
                    {cpf}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={styles.containerSelectFilter}>
              <div className={styles.label}>
                <p>Colaborador</p>
              </div>
              <Select
                id='colaborador-select'
                showSearch
                allowClear
                placeholder='Selecione o Colaborador'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  handleFilterChange('colaborador', value);
                  setSelectedColaborador(value); // Atualiza o estado selecionado
                }}
                value={selectedColaborador} // Sincroniza o estado com o valor do Select
                style={{ marginRight: 8 }}
              >
                {uniqueColab.map((colab) => (
                  <Option key={colab} value={colab}>
                    {colab}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Divider />
          <div className={styles.buttons}>
            <Button onClick={handleLimparFiltros} className={styles.buttonLimpar}>
              Limpar Filtros
            </Button>
            <Button className={styles.buttonCancelar} onClick={handleCancelarClick}>
              Cancelar
            </Button>
          </div>
        </div>
      )}
      <div className={styles.tableContainer}>
  <table>
    <thead>
      <tr>
        <th>Ações</th>
        <th>Status</th>
        <th>Cliente</th>
        <th>CPF</th>
        <th>Operação</th>
        <th>Capital</th>
        <th>Data Emp.</th>
        <th>Próx. Pagto.</th>
        <th>Vlr. Total</th>
        <th>N° Parcela</th>
        <th>Colaborador</th>
        <th>Status</th>
        {/* <th>Verificar Pagamento</th> */}
      </tr>
    </thead>
    <tbody>
      {paginatedData.length > 0 ? (
        paginatedData.map((row, index) => (
          <tr key={index}>
            <td style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
              <Button icon={<MdOutlineRemoveRedEye />} onClick={() => (row.tipoOperacao !== 'mensal' ? abrirModalSubOperacoes(row.subOperacoes) : abrirModalMensal(row.subOperacoes))} />
              <Button icon={<AiOutlineFile />} onClick={() => mostrarRecibo(row)} />
              <Button icon={<MdOutlineDelete style={{ color: 'red' }} />} onClick={() => deletarOperacao(row.id)} />
            </td>
            {/* <td>{row.status === 'aprovada' ? <Button icon={<FaPix />} onClick={() => openModalPix(row)} alt='Ver parcelas'></Button> : <></>}</td> */}
            <td>{row['etapa']}</td>
            <td className={styles.columnCliente}>{row['cliente'].nome}</td>
            <td>{row['cliente'].cpf}</td>
            <td>{capitalizeFirstLetter(row['tipoOperacao'])}</td>
            <td>{formatarComoMoeda(row['valorEmprestimo'])}</td>
            {/* <td>{row['numeroParcelas']}</td> */}
            <td>{formatarData(row['dataEmprestimo'])}</td>
            <td>{formatarData(row['proximaParcela'])}</td>
            <td className={styles.rowTableValueTotal}>
              <p>{formatarComoMoeda(row['valorTotal'])}</p>
            </td>
            <td>{row['numeroParcelas']}</td>
            <td>{row.usuario.nome}</td>

            <td>
              {row['statusPagamento'] === 'Pago' ? (
                <div className={styles.statusContainer}>
                  <div className={styles.circlePagoValue}></div>
                  <p className={styles.pagoValue}>Pago</p>
                </div>
              ) : (
                <div className={styles.statusContainerNaoPagoValue}>
                  <div className={styles.circleNaoPagoValue}></div>
                  <p className={styles.naoPagoValue}>Não pago</p>
                </div>
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={12} style={{ textAlign: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>


      <Pagination
        current={currentPage}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        pageSize={pageSize}
        total={data.length}
        showSizeChanger
        pageSizeOptions={['5', '10', '15', '25']}
        className={styles.cotnainerPagination}
      />

      <Modal title='Anotações' open={isModalOpenAnotacoes} onOk={() => setIsModalOpenAnotacoes(false)} onCancel={() => setIsModalOpenAnotacoes(false)}>
        <p>{modalContentAnotacoes}</p>
      </Modal>
      <ModalParcelas
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalContent={modalContent}
        getEtapaID={getEtapaID}
        atualizarEtapaOperacao={atualizarEtapaOperacao}
        formatarComoMoeda={formatarComoMoeda}
        formatarData={formatarData}
        setIsModalOpenComprovantes={setIsModalOpenComprovantes}
        uploadProps={uploadProps}
      />
      <ModalMensal
        isModalOpen={isModalOpenMensal}
        setIsModalOpen={setIsModalOpenMensal}
        modalContent={modalContent}
        getEtapaID={getEtapaID}
        atualizarEtapaOperacao={atualizarEtapaOperacao}
        formatarComoMoeda={formatarComoMoeda}
        formatarData={formatarData}
        setIsModalOpenComprovantes={setIsModalOpenComprovantes}
        uploadProps={uploadProps}
      />
      <Modal
        title='Recibo da Operação'
        visible={reciboVisible}
        onOk={() => setReciboVisible(false)}
        onCancel={() => setReciboVisible(false)}
        footer={[
          <Button key='print' type='primary' onClick={enviarReciboWhatsApp}>
            Enviar Recibo
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: reciboTexto }} />
      </Modal>
      <Modal
        title='Editar Operação'
        open={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
        footer={[
          <Button key='back' onClick={() => setIsEditModalOpen(false)}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical' onFinish={updateOperacao}>
          <Form.Item name='etapa' label='Etapa'>
            <Input />
          </Form.Item>
          <Form.Item name='tipoOperacao' label='Tipo de Operação'>
            <Select>
              <Select.Option value='mensal'>Mensal</Select.Option>
              <Select.Option value='parcial'>Parcial</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='valorEmprestimo' label='Valor do Empréstimo'>
            <Input type='number' prefix='R$' />
          </Form.Item>
          <Form.Item name='prazo' label='Prazo'>
            <Input />
          </Form.Item>
          <Form.Item name='dataEmprestimo' label='Data do Empréstimo'>
            <Input type='date' />
          </Form.Item>
          <Form.Item name='proximaParcela' label='Próxima Parcela'>
            <Input type='date' />
          </Form.Item>
          <Form.Item name='valorTotal' label='Valor Total'>
            <Input type='number' prefix='R$' />
          </Form.Item>
          <Form.Item name='numeroParcelas' label='Número de Parcelas'>
            <Input type='number' />
          </Form.Item>
          <Form.Item name='juros' label='Juros (%)'>
            <Input type='number' addonAfter='%' />
          </Form.Item>
          <Form.Item name='statusPagamento' label='Status do Pagamento'>
            <Select>
              <Select.Option value='pago'>Pago</Select.Option>
              <Select.Option value='naoPago'>Não Pago</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='verificarPagamento' label='Verificar Pagamento'>
            <Select>
              <Select.Option value='sim'>Sim</Select.Option>
              <Select.Option value='nao'>Não</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='anotacoes' label='Anotações'>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='textNegado' label='Texto de Negado'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {loadingMore && <Spin />}
    </div>
  );
};

export default TableContratos;
