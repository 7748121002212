import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Modal, message, Tabs, Popover, Breadcrumb, Empty, Table, Image, Input, Form } from 'antd';
import { api } from '../../../services/apiClient';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import ModalPix from '../../../Components/ModalPix';
import RowAprovacoes from '../../../Components/RowAprovacoes';
import StepsSeeClient from '../../../Components/StepsSeeClient';
import TableConfirmado from '../../../Components/TableConfirmado';
import { FaEye } from 'react-icons/fa';

const { TabPane } = Tabs;

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const AConfirmar = () => {
  const [loadingModal, setLoadingModal] = useState(false);
  const { user } = useAuth();
  const [operacoes, setOperacoes] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [acao, setAcao] = useState(null); // Novo estado para ação
  const [subOperacaoId, setSubOperacaoId] = useState(null); // Novo estado para ID da suboperação
  const [loadingConfirmacao, setLoadingConfirmacao] = useState(false);

  const fetchOperacoes = async () => {
    try {
      const response = await api.get(`/suboperation/confirmar`);
      setOperacoes(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchOperacoes();
  }, []);

  const handleAceitar = async () => {
    try {
      const response = await api.put('/suboperacao/confirmar', { subOperacaoId, aceitar: true });
      fetchOperacoes();
      message.success('Operação marcada como paga com sucesso!');
      setModalVisible(false);
    } catch (error) {
      console.log('error', error.response?.data || error.message);
      message.error('Não foi possível concluir a operação!');
    }
  };

  const handleNegar = async () => {
    try {
      const response = await api.put('/suboperacao/confirmar', { subOperacaoId, aceitar: false });
      fetchOperacoes();
      message.success('Operação negada com sucesso!');
      setModalVisible(false);
    } catch (error) {
      console.log('error', error.response?.data || error.message);
      message.error('Não foi possível negar a operação!');
    }
  };

  const handleConfirmarAcao = async (acao) => {
    setLoadingModal(true);

    try {
      if (acao === 'aceitar') {
        await handleAceitar();
      } else if (acao === 'negar') {
        await handleNegar();
      }

      await fetchOperacoes(); // Atualiza a lista de operações após a ação
      setLoadingModal(false);
      setModalVisible(false);
      message.success(`Operação ${acao === 'aceitar' ? 'aprovada' : 'negada'} com sucesso!`);
    } catch (error) {
      console.log('error', error.response?.data || error.message);
      message.error(`Não foi possível ${acao === 'aceitar' ? 'aprovar' : 'negar'} a operação!`);
      setLoadingModal(false);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const formatarValor = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor);
  };

  const columns = [
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              setAcao(null); // Limpar a ação anterior
              setSubOperacaoId(record.id);
              setClienteSelecionado(record); // Define o cliente selecionado
              setModalVisible(true);
            }}
            type='primary'
            className={styles.buttonAceitar}
            size='small'
          >
            Analisar
          </Button>
        );
      },
    },
    {
      title: 'Cliente',
      dataIndex: ['operacao', 'cliente', 'nome'],
      key: 'clienteNome',
    },
    {
      title: 'Valor da parcela',
      dataIndex: 'valorParcela',
      key: 'valorParcela',
      render: (valor) => {
        console.log('valor', valor);
        if (!valor) return 'R$ 0,00';
        return `R$ ${valor?.toFixed(2)}`;
      },
    },
    {
      title: 'Valor multa',
      dataIndex: 'valorMulta',
      key: 'valorMulta',
      render: (valor) => {
        console.log('valor', valor);
        if (!valor) return 'R$ 0,00';
        return `R$ ${valor?.toFixed(2)}`;
      },
    },
    {
      title: 'Pago da parcela',
      dataIndex: 'valorPago',
      key: 'valorPago',
      render: (valor) => `R$ ${valor.toFixed(2)}`,
    },
    {
      title: 'Total Pago',
      dataIndex: 'valorParcela',
      key: 'valorParcela',
      render: (valor, record) => `R$ ${(record.valorMulta + record.valorPago).toFixed(2)}`,
    },
    {
      title: 'Colaborador',
      dataIndex: ['operacao', 'usuario', 'nome'],
      key: 'usuarioNome',
    },
    {
      title: 'Data de vencimento',
      dataIndex: 'dataVencimento',
      key: 'dataVencimento',
      render: (text) => new Date(text).toLocaleDateString('pt-BR'),
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <Breadcrumb
          items={[
            {
              title: (
                <Link to='/'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                      fill='#757575'
                    />
                  </svg>
                </Link>
              ),
            },
            {
              title: (
                <>
                  <span>Operações</span>
                </>
              ),
            },
            {
              title: 'A Confirmar',
            },
          ]}
        />
      </div>
      <div className={styles.operacaoContainer}>
        <Table columns={columns} bordered dataSource={operacoes} className={styles.table} />
      </div>
      <Modal visible={modalVisible} onCancel={handleCancel} footer={null}>
        {clienteSelecionado && (
          <div>
            <h3>Detalhes da Operação - Colaborador: {clienteSelecionado.operacao.usuario?.nome}</h3>

            <Form layout='vertical'>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='Nome do Cliente'>
                    <Input value={clienteSelecionado.operacao.cliente.nome} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='CPF do Cliente'>
                    <Input value={clienteSelecionado.operacao.cliente.cpf} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='Data de Vencimento'>
                    <Input value={new Date(clienteSelecionado.dataVencimento).toLocaleDateString('pt-BR')} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='Valor Parcela'>
                    <Input value={`R$ ${clienteSelecionado.valorParcela.toFixed(2)}`} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='Valor Multa'>
                    <Input value={`R$ ${clienteSelecionado?.valorMulta?.toFixed(2)}`} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item label='Valor Pago'>
                    <Input value={`R$ ${(clienteSelecionado.valorParcela + clienteSelecionado.valorMulta).toFixed(2)}`} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className={styles.comprovantes}>
              <h4>Comprovantes</h4>
              {clienteSelecionado.comprovantesPagamento && clienteSelecionado.comprovantesPagamento.length > 0 ? (
                clienteSelecionado.comprovantesPagamento.map((comprovante, index) => (
                  <div key={index}>
                    <Image.PreviewGroup>
                      <Image key={`doc-${index}`} width={200} src={`${baseURL}${comprovante}`} />
                    </Image.PreviewGroup>
                  </div>
                ))
              ) : (
                <Empty description='Nenhum comprovante enviado' />
              )}
            </div>
            <div className={styles.modalActions}>
              <Button onClick={() => handleConfirmarAcao('negar')} type='danger' className={styles.buttonNegar}>
                {loadingModal ? <LoadingOutlined /> : 'Negar'}
              </Button>

              <Button onClick={() => handleConfirmarAcao('aceitar')} type='primary' className={styles.buttonAceitar}>
                {loadingModal ? <LoadingOutlined /> : 'Aprovar'}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AConfirmar;
