import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import TableUsuarios from '../../../Components/TableUsuarios'

const UsuariosBase = () => {
  const [userData, setUserData] = useState([])


  const fetchData = async () => {
    try {
      const response = await api.get(`/user-all`);
      
      setUserData(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {

    fetchData();
  }, []);



  return (
    <TableUsuarios data={userData} fetchData={fetchData} />
  );  
};

export default UsuariosBase;
