import React from 'react';
import { Layout, Menu, Avatar, Button, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { IoClipboardOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/logosidebar.png'

import styles from './style.module.scss';
import { useMenuData } from '../../menuData'; // Certifique-se de que o caminho está correto

const { Sider } = Layout;

const SidebarOfficial = ({ collapsed, toggleCollapsed }) => {
  const { user, logout } = useAuth();
  const menuData = useMenuData(); // Usando o hook customizado para obter os dados do menu

  const handleLogout = () => {
    logout(); // Lógica de logout
  };

  const renderMenuItem = (item) => (
    <Menu.Item key={item.key} icon={item.icon} className={styles.leftAlign}>
      {item.to ? <Link to={item.to}>{item.title}</Link> : <p style={{ color: '#AA965D' }} >{item.title}</p>}
    </Menu.Item>
  );

  const renderSubMenu = (submenu) => {
    // Filtrar itens do submenu com base no nível de usuário
    const visibleChildren = submenu.children.filter((child) => child.isVisible && user.tipoUsuario <= child.requiredUserLevel);

    // Se o submenu não deve ser visível ou todos os seus itens forem filtrados, não renderiza o submenu
    if (!submenu.isVisible || user.tipoUsuario > submenu.requiredUserLevel || visibleChildren.length === 0) {
      return null;
    }

    return (
      <Menu.SubMenu key={submenu.key} icon={submenu.icon} title={submenu.title} className={styles.leftAlign}>
        {visibleChildren.map((child) => renderMenuItem(child))}
      </Menu.SubMenu>
    );
  };

  const renderMenuItems = () => {
    const filteredItems = menuData.filter((item) => item.isVisible && user?.tipoUsuario <= item.requiredUserLevel);

    return filteredItems.flatMap((item, index) => {
      const menuItem = item.children ? renderSubMenu(item) : renderMenuItem(item);
      const divider = item.hasDividerBefore ? <Divider key={`divider-${index}`} /> : null;
      return divider ? [divider, menuItem] : menuItem;
    });
  };

  const formatFullName = (fullName) => {
    if (!fullName) return '';
  
    // Dividir o nome completo em partes (nomes e sobrenomes)
    const parts = fullName.split(' ');
  
    // Formatar cada parte (nome e sobrenome)
    const formattedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
  
    // Juntar as partes formatadas em um nome completo formatado
    return formattedParts.join(' ');
  };
  

  return (
    <Sider width={256} collapsible reverseArrow={false} collapsed={collapsed} onCollapse={toggleCollapsed} breakpoint='md' collapsedWidth='0' theme='light' className={styles.sidebar}>
      <div className={styles.logosidebar}>
        <img src={logo} ></img>
      </div>
      <Menu inlineCollapsed={true} theme='light' mode='inline' defaultSelectedKeys={['dashboard']} style={{ minHeight: 'auto', overflowX: 'auto', borderRight: 0, backgroundColor: '#FFFFFF' }}>
        {renderMenuItems()}
      </Menu>
      <div>
        <div className={styles.sidebarFooter}>
          <Button
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V9H5V5H19V19H5V15H3V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#EB4143" />
            </svg>}
            type='text'
            onClick={handleLogout}
            className={styles.button}>
            Sair
          </Button>
        </div>
        <Divider style={{ borderColor:'#D9D9D9' }}></Divider>
        {/* Código do usuário */}
        <div className={styles.userProfile}>
          <Avatar size={45}
            icon={user?.avatar ? <Avatar src={user.avatar} size={45} /> : <Avatar icon={<UserOutlined />} size={45} />}
          />
          <div className={styles.userInfo}>
          <h1>{user?.username}</h1>
            {/* <p>{user?.email}</p> */}
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default SidebarOfficial;
