// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_containerGraficos__We6RV {
  border-radius: 6px;
  background: var(--Foundation-Beaver-beaver-1, #F5F3EF);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.style_containerGraficos__We6RV h2 {
  color: var(--Foundation-Beaver-beaver-6, #9A8B60);
  /* Text Body/Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 175% */
  padding: 1rem 0.5rem 0.5rem 1.5rem;
}

.style_status__PuYc7 {
  padding: 3rem;
  height: 300px;
}

.style_mes__E9A3i {
  padding: 1rem;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Graphs/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sDAAA;EACA,+CAAA;AACJ;AACI;EACI,iDAAA;EAEA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;EACA,kCAAA;AAAR;;AAIA;EACI,aAAA;EACA,aAAA;AADJ;;AAIA;EACI,aAAA;EACA,aAAA;AADJ","sourcesContent":[".containerGraficos {\n    border-radius: 6px;\n    background: var(--Foundation-Beaver-beaver-1, #F5F3EF);\n    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);\n\n    h2 {\n        color: var(--Foundation-Beaver-beaver-6, #9A8B60);\n\n        /* Text Body/Bold */\n        font-family: Inter;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 28px;\n        /* 175% */\n        padding: 1rem .5rem .5rem 1.5rem;\n    }\n}\n\n.status {\n    padding: 3rem;\n    height: 300px;\n}\n\n.mes {\n    padding: 1rem;\n    height: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerGraficos": `style_containerGraficos__We6RV`,
	"status": `style_status__PuYc7`,
	"mes": `style_mes__E9A3i`
};
export default ___CSS_LOADER_EXPORT___;
