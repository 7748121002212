import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import "react-datepicker/dist/react-datepicker.css";

function RootComponent() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <App />
        <div id="modal-root"></div>
      </AuthProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
