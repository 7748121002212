import React, { useState, useMemo } from 'react';
import { Dropdown, Menu, Modal, Pagination, Select, Button, message, Upload, Image, Row, Col, Form, Input } from 'antd';
import styles from './style.module.scss';
import { FaRegEye, FaEdit, FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { api } from '../../services/apiClient';
import { useAuth } from '../../contexts/AuthContext';

const { Option } = Select; // Importação do Option

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const ModalEditingClientes = ({ editingCliente, isModalOpenEdicao, setIsModalOpenEdicao, handleUpdateCliente, loadingSaveEditing, isEditable }) => {

  const [cepResidencial, setCepResidencial] = useState(''); // Estado para armazenar o CEP
  const [logradouroResidencial, setLogradouroResidencial] = useState(''); // Estado para o logradouro
  const [bairroResidencial, setBairroResidencial] = useState(''); // Estado para o bairro
  const [cidadeResidencial, setCidadeResidencial] = useState(''); // Estado para a cidade
  const [estadoResidencial, setEstadoResidencial] = useState(''); // Estado para o estado
  const [numeroResidencial, setNumeroResidencial] = useState('');
  const [cepComercial, setCepComercial] = useState(''); // Estado para armazenar o CEP
  const [logradouroComercial, setLogradouroComercial] = useState(''); // Estado para o logradouro
  const [bairroComercial, setBairroComercial] = useState(''); // Estado para o bairro
  const [cidadeComercial, setCidadeComercial] = useState(''); // Estado para a cidade
  const [estadoComercial, setEstadoComercial] = useState(''); // Estado para o estado
  const [numeroComercial, setNumeroComercial] = useState('');

  const handleCepChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setCepResidencial(valor);
    if (valor.length === 8 && editingCliente && editingCliente.id) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setLogradouroResidencial(response.data.logradouro);
        setBairroResidencial(response.data.bairro);
        setCidadeResidencial(response.data.localidade);
        setEstadoResidencial(response.data.uf);
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };  

  const handleCepComercialChange = async (e) => {
    const valor = e.target.value.replace(/\D/g, ''); // Remove tudo que não for dígito
    setCepComercial(valor);
    if (valor.length === 8 && editingCliente && editingCliente.id) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${valor}/json/`);
        setLogradouroComercial(response.data.logradouro);
        setBairroComercial(response.data.bairro);
        setCidadeComercial(response.data.localidade);
        setEstadoComercial(response.data.uf);
        // Aqui você pode atualizar o estado do endereço residencial ou comercial com os dados da API
        // Por exemplo: setEnderecoResidencial(`${response.data.logradouro}, ${response.data.bairro}`);
      } catch (error) {
        console.error('Erro ao buscar o CEP', error);
      }
    }
  };

  return (
    <Modal key={editingCliente && editingCliente.id} footer={null} width={900} title='Editar Cliente' open={isModalOpenEdicao} onOk={() => setIsModalOpenEdicao(false)} onCancel={() => setIsModalOpenEdicao(false)}>
      <Form
        layout='vertical'
        initialValues={editingCliente}
        onFinish={handleUpdateCliente}
        key={editingCliente && editingCliente.id} // Ensure form re-renders with new data
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label='Nome' name='nome'>
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item label='CPF' name='cpf'>
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item label='RG' name='rg'>
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item label='Celular' name='celular'>
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item label='Apelido' name='apelido'>
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item label='Data de Nascimento' name='dataNascimento'>
              <Input disabled={!isEditable} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='CEP Comercial' name='cepComercial'>
              <Input disabled={!isEditable} value={cepComercial} onChange={handleCepComercialChange} />
            </Form.Item>
            <Form.Item label='Logradouro Comercial'>
              <Input disabled={!isEditable} value={logradouroComercial ? logradouroComercial : editingCliente?.logradouroComercial} onChange={(e) => setLogradouroComercial(e.target.value)} />
            </Form.Item>
            <Form.Item label='Número Comercial'>
              <Input disabled={!isEditable} value={numeroComercial ? numeroComercial : editingCliente?.numeroComercial} onChange={(e) => setNumeroComercial(e.target.value)} />
            </Form.Item>
            <Form.Item label='Bairro Comercial'>
              <Input disabled={!isEditable} value={bairroComercial ? bairroComercial : editingCliente?.bairroComercial} onChange={(e) => setBairroComercial(e.target.value)}  />
            </Form.Item>
            <Form.Item label='Cidade Comercial'>
              <Input disabled={!isEditable} value={cidadeComercial ? cidadeComercial : editingCliente?.cidadeComercial} onChange={(e) => setCidadeComercial(e.target.value)}  />
            </Form.Item>
            <Form.Item label='Estado Comercial'>
              <Input disabled={!isEditable} value={estadoComercial ? estadoComercial : editingCliente?.estadoComercial} onChange={(e) => setEstadoComercial(e.target.value)}  />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='CEP Residencial' name='cepResidencial'>
              <Input disabled={!isEditable} value={cepResidencial} onChange={handleCepChange} />
            </Form.Item>
            <Form.Item label='Logradouro Residencial' >
              <Input disabled={!isEditable} value={logradouroResidencial ? logradouroResidencial : editingCliente?.logradouroResidencial} onChange={(e) => setNumeroResidencial(e.target.value)}/>
            </Form.Item>
            <Form.Item label='Número Residencial'>
              <Input disabled={!isEditable} value={numeroResidencial ? numeroResidencial : editingCliente?.numeroResidencial} onChange={(e) => setNumeroResidencial(e.target.value)} />
            </Form.Item>
            <Form.Item label='Bairro Residencial'>
              <Input disabled={!isEditable} value={bairroResidencial ? bairroResidencial : editingCliente?.bairroResidencial} onChange={(e) => setBairroResidencial(e.target.value)} />
            </Form.Item>
            <Form.Item label='Cidade Residencial'>
              <Input disabled={!isEditable} value={cidadeResidencial ? cidadeResidencial : editingCliente?.cidadeResidencial} onChange={(e) => setCidadeResidencial(e.target.value)} />
            </Form.Item>
            <Form.Item label='Estado Residencial'>
              <Input disabled={!isEditable} value={estadoResidencial ? estadoResidencial : editingCliente?.estadoResidencial} onChange={(e) => setEstadoResidencial(e.target.value)} />
            </Form.Item>

          </Col>
          <Col span={24} className={styles.divBotaoSalvarEditar}>
            <Button loading={loadingSaveEditing} type='primary' htmlType='submit' disabled={!isEditable}>
              Salvar Alterações
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditingClientes;
