import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, Form, Col, Row, Select, message, Upload, Input, Divider } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { Breadcrumb, Switch } from 'antd';
import { api } from '../../services/apiClient';
import CurrencyInput from 'react-currency-input-field';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { IoEyeSharp } from 'react-icons/io5';
import { FaWhatsapp } from 'react-icons/fa';

const { Option } = Select;

const TableColabores = ({
  data,
  onChildUpdate,
  clientNames = [],
  progress = 100,
  selectedDay = null,
  setSelectedDay = null,
  selectedMonth = null,
  setSelectedMonth = null,
  selectedYear = null,
  setSelectedYear = null,
  selectedCliente = null,
  setSelectedCliente = null,
}) => {
  const [valorPago, setValorPago] = useState(0);
  const [valorPagoMulta, setValorPagoMulta] = useState(0);
  const [valorParcela, setValorParcela] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [valorTotalOperacao, setValorTotalOperacao] = useState(0);
  const [valorTotalInput, setValorTotalInput] = useState('');
  const [novaEtapaChange, setNovaEtapaChange] = useState('');
  const [isModalOpenComprovantes, setIsModalOpenComprovantes] = useState(false);
  const [comprovanteFileList, setComprovanteFileList] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState('');
  const [outroPagamento, setOutroPagamento] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [operacaoIdChange, setOperacaoIdChange] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const [multaAtiva, setMultaAtiva] = useState(false);
  const [valorMulta, setValorMulta] = useState(0);
  const [loadingMulta, setLoadingMulta] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('todos');
  const [enviarParaAcordo, setEnviarParaAcordo] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    cliente: null,
    etapas: null,
    cpf: null,
    colaborador: null,
    operacao: null,
    dia: null,
  });

  const [isModalVerclienteOpen, setIsModalVerClienteOpen] = useState(false);
  const [modalVercliente, setModalVerCliente] = useState(false);

  const uniqueClientes = [...new Set(data.map((item) => item.operacao.cliente.nome))];
  const [searchBarVisible, setSearchBarVisible] = useState(true);
  const [saldoRestanteTotal, setSaldoRestanteTotal] = useState(0);
  const [valorEmprestimo, setValorEmprestimo] = useState(0);
  const [sortedInfo, setSortedInfo] = useState({});
  const [operacaoPaiId, setOperacaoPaiId] = useState();
  const [operacaoCompleta, setOperacaoCompleta] = useState();
  const [visibleData, setVisibleData] = useState([]);
  const [page, setPage] = useState(1);

  const [mensagem, setMensagem] = useState('');
  const [telefone, setTelefone] = useState(''); // Inicia com o número fornecido ou vazio
  const [tokenEnvio, setTokenEnvio] = useState(''); // Inicia com o número fornecido ou vazio
  const [modalMensagem, setModalMensagem] = useState(false); // Inicia com o número fornecido ou vazio

  const today = new Date();

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.dataVencimento);
      const monthMatches = selectedMonth ? itemDate.getMonth() + 1 === selectedMonth : true;
      const yearMatches = selectedYear ? itemDate.getFullYear() === selectedYear : true;
      return monthMatches && yearMatches;
    });
    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  }, [data, selectedMonth, selectedYear]);

  useEffect(() => {
    setValorTotal(valorParcela + valorMulta);
  }, [valorParcela, valorMulta]);

  const splitPayment = (valor) => {
    console.log('valor', valor);
    const multaCalculada = parseFloat(valorMulta);
    const restante = parseFloat(valor) - multaCalculada;
    setValorTotalInput(valor);
    setValorPagoMulta(restante);
    setValorPago(multaCalculada);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
      loadMoreData();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleData, tableData]);

  const loadMoreData = () => {
    const newData = tableData.slice(visibleData.length, visibleData.length + 20);
    setVisibleData([...visibleData, ...newData]);
    setPage(page + 1);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    let filteredData = data;
    today.setHours(0, 0, 0, 0);

    if (value === 'pago') {
      filteredData = data.filter((item) => item.status === 'pago');
    } else if (value === 'Aberta') {
      // filteredData = data.filter((item) => item.status === "Aberta");

      // filteredData = data.filter((item) => {
      //   const dueDate = new Date(item.dataVencimento);
      //   console.log('dueDate', dueDate)
      //   console.log('today', today)
      //   return item.status == 'Aberta' ;
      // });

      filteredData = data.filter((item) => {
        const dueDate = new Date(item.dataVencimento);
        dueDate.setHours(0, 0, 0, 0);
        return dueDate.getTime() === today.getTime() && item.status === 'Aberta';
      });
    } else if (value === 'atrasada') {
      filteredData = data.filter((item) => {
        const dueDate = new Date(item.dataVencimento);
        return dueDate < today && item.status !== 'pago';
      });
    }
    console.log('filteredData', filteredData.length);
    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  };

  const handleActionClick = async (record) => {
    setFormaPagamento('');
    setComprovanteFileList([]);
    setOperacaoIdChange(record.id);
    setIsModalOpenComprovantes(true);
    setValorParcela(record.valorParcela);
    setSaldoRestanteTotal(record.operacao.saldoRestanteTotal);
    setValorEmprestimo(record.operacao.valorEmprestimo);
    setValorTotal(record.operacao.valorTotal);
    setOperacaoPaiId(record.operacao.id);
    setOperacaoCompleta(record);
    setValorTotalOperacao(record.operacao.valorEmprestimo + record.saldoRestante);

    const dueDate = new Date(record.dataVencimento);
    if (dueDate < today) {
      setMultaAtiva(true);
      await calcularMulta(record.id, record.saldoRestante);
    } else {
      setMultaAtiva(false);
      setValorMulta(0);
    }
    setValorPago(parseFloat((record.saldoRestante + valorMulta).toFixed(2)));
  };

  const calcularMulta = async (id, saldoRestante) => {
    setLoadingMulta(true);
    try {
      const response = await api.put(`/suboperation/multa/${id}`, { multaAtiva: true });
      const multaCalculada = parseFloat(response.data.valorMulta.toFixed(2));
      setValorMulta(multaCalculada);
      setValorPago(parseFloat((saldoRestante + multaCalculada).toFixed(2)));
      message.success('Multa calculada com sucesso!');
    } catch (error) {
      message.error('Erro ao calcular multa!');
    }
    setLoadingMulta(false);
  };

  const calculateDisplayStatus = (record) => {
    const dueDate = new Date(record.dataVencimento);
    const status = record.status;
    const isAtrasada = dueDate < today && status !== 'pago';
    return isAtrasada ? 'atrasado' : status;
  };
  const formatarComoMoeda = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valor); // Divisão por 100 para corrigir o formato
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    if (isNaN(data.getTime())) {
      console.error('Data inválida:', dataString);
      return 'Data inválida'; // ou alguma outra ação de erro
    }
    return new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      timeZone: 'UTC',
    }).format(data);
  };

  const veroperacao = async (operacaoId) => {
    console.log('operacaoId', operacaoId);
    try {
      const response = await api.get(`/operation/${operacaoId}`);
      console.log('response', response.data);

      // Verifica se as operações foram retornadas corretamente
      const { operacoes } = response.data;

      if (operacoes && operacoes.length > 0) {
        const operacao = operacoes[0]; // Supondo que você queira mostrar a primeira operação, ajuste conforme necessário
        setIsModalVerClienteOpen(true);
        setModalVerCliente(operacao.subOperacoes); // Seta as subOperações no modal
      } else {
        message.warning('Nenhuma operação encontrada.');
      }
    } catch (error) {
      console.error('Erro ao carregar a operação:', error.message);
      message.error('Erro ao carregar a operação.');
    }
  };

  const handleSendMessage = async (operacao) => {
    console.log('operacao', operacao);
    setTokenEnvio(operacao?.operacao?.usuario.tokenPolaplon);
    // console.log('operacao?.operacao?.usuario?.tokenEnvio', operacao?.operacao?.usuario?.tokenEnvio)
    console.log('operacao?.operacao', operacao?.operacao?.usuario.tokenPolaplon);
    if (!operacao?.operacao?.usuario?.tokenPolaplon) {
      message.success('Usuario não tem token cadastrado');
      return;
    }
    setMensagem(`Bom dia, ${operacao?.operacao?.cliente?.nome}
segue sua parcela ${formatarComoMoeda(operacao?.saldoRestante)} para vencimento ${formatarData(operacao?.dataVencimento)}

CHAVE PIX
CPF: 25604257850
NOME FAVORECIDO: Odair Colturato
BANCO: Santander

@Favor enviar o comprovante de pagamento 🧾`);
    setTelefone(operacao?.operacao?.cliente?.celular);

    setModalMensagem(true);
  };

  const enviarMensagem = async () => {
    if (!mensagem || !telefone || !tokenEnvio) {
      message.warning('Preencha ambos os campos.');
      return;
    }

    const url = 'https://api.polaplon.com.br/api/messages/send';

    const payload = {
      number: `55${telefone}`,
      body: mensagem,
    };

    console.log('tokenEnvio', tokenEnvio);
    console.log('payload', payload);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokenEnvio}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      console.log('response', response);
      if (response.ok) {
        const data = await response.json();
        console.log('Mensagem enviada com sucesso:', data);
        setModalMensagem(false);
        setMensagem('');
        message.success('Mensagem enviada com sucesso');

        return data; // Retorna os dados recebidos da API
      } else {
        const errorData = await response.json();
        console.error('Erro ao enviar a mensagem:', errorData);
        throw new Error(errorData.message || 'Erro ao enviar a mensagem.');
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      throw error;
    }
  };

  const columns = [
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => (
        <div className={styles.rowButtonsTable}>
          <Button className={styles.buttonPagar} onClick={() => handleActionClick(record)} type='primary' size='small' disabled={record.status === 'pago'}>
            <MdOutlineAttachMoney />
          </Button>
          <Button className={styles.buttonPagar} onClick={() => veroperacao(record.operacao.id)} type='primary' size='small' disabled={record.status === 'pago'}>
            <IoEyeSharp />
          </Button>
        </div>
      ),
    },
    {
      title: 'Cliente',
      dataIndex: 'operacao',
      key: 'operacao',
      render: (operacao, record) => {
        if (operacao.cliente.nome && operacao.cliente.nome.length > 0) {
          const palavras = operacao.cliente.nome.split(' ');
          const nomeFormatado = palavras
            .map((palavra) => {
              return palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
            })
            .join(' ');
          return nomeFormatado;
        }
        return '';
      },
    },
    {
      title: 'Valor da Parcela',
      dataIndex: 'valorParcela',
      key: 'valorParcela',
      render: (value) =>
        value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Data de Vencimento',
      dataIndex: 'dataVencimento',
      key: 'dataVencimento',
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
      },
      sorter: (a, b) => new Date(a.dataVencimento) - new Date(b.dataVencimento),
      sortOrder: sortedInfo.columnKey === 'dataVencimento' && sortedInfo.order,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        const today = new Date();
        const date = new Date(record.dataVencimento);
        const dueDateZero = date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
        const todayZero = today.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

        const isAtrasada = dueDateZero < todayZero && status !== 'pago';
        const displayStatus = isAtrasada ? 'atrasado' : status;

        // Definindo estilos para tag e círculo
        const tagStyle = {
          backgroundColor: isAtrasada ? '#FFD3D3' : status === 'pago' ? '#DDFFCD' : '#FFE6C0',
          color: isAtrasada ? '#F90000' : status === 'pago' ? '#007F00' : '#F99500',
          padding: '8px 8px 8px 24px',
          borderRadius: '30px',
          border: 'none',
          width: '100px',
          textAlign: 'center',
          position: 'relative',
        };

        const circleStyle = {
          backgroundColor: isAtrasada ? '#F90000' : status === 'pago' ? '#007F00' : '#F99500',
          position: 'absolute',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
        };

        return (
          <Tag style={tagStyle}>
            <span style={circleStyle}></span>
            {displayStatus.toUpperCase()}
          </Tag>
        );
      },

      sorter: (a, b) => {
        const statusOrderAsc = ['aberta', 'atrasado', 'pago'];
        const statusOrderDesc = ['pago', 'parcial', 'aberta'];

        const displayStatusA = calculateDisplayStatus(a);
        const displayStatusB = calculateDisplayStatus(b);

        if (sortedInfo.order === 'ascend') {
          return statusOrderAsc.indexOf(displayStatusA) - statusOrderAsc.indexOf(displayStatusB);
        } else {
          return statusOrderDesc.indexOf(displayStatusA) - statusOrderDesc.indexOf(displayStatusB);
        }
      },
      sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
    },
    {
      title: 'Valor Restante',
      dataIndex: 'saldoRestante',
      key: 'saldoRestante',
      render: (value) =>
        value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Colaborador',
      dataIndex: 'colaborador',
      key: 'colaborador',
      render: (value, record) => {
        return <p>{record.operacao.usuario.nome}</p>;
      },
    },
    {
      title: 'Operação',
      dataIndex: 'operacao',
      key: 'operacao',
      render: (operacao) => {
        if (operacao.tipoOperacao && operacao.tipoOperacao.length > 0) {
          const operacaoCapitalizada = operacao.tipoOperacao.charAt(0).toUpperCase() + operacao.tipoOperacao.slice(1);
          return operacaoCapitalizada;
        }
        return '';
      },
    },
    {
      title: 'Mensagem',
      key: 'action',
      render: (_, record) => (
        <div className={styles.rowButtonsTable}>
          <Button className={styles.buttonPagar} onClick={() => handleSendMessage(record)} type='primary' size='small' disabled={record.status === 'pago'}>
            <FaWhatsapp />
          </Button>
        </div>
      ),
    },
  ];

  const handleComprovanteChange = ({ fileList }) => setComprovanteFileList(fileList);

  const handleUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await api.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onSuccess(response.data.data.Key);
    } catch (error) {
      onError(error);
    }
  };

  const atualizarParcelaParaPago = async () => {
    setLoadingModal(true);

    try {
      const urlsFotosDocumento = await Promise.all(
        comprovanteFileList.map(async (file) => {
          if (!file.url && !file.response) {
            const response = await handleUpload({ file: file.originFileObj });
            return response;
          }
          return file.url || file.response;
        })
      );

      if (enviarParaAcordo) {
        const valorPagoMultaFloat = parseFloat(valorPagoMulta.replace(',', '.'));

        const dataPago = {
          id: operacaoPaiId,
          valorPago: valorPagoMultaFloat,
          comprovantesNovos: urlsFotosDocumento,
          formaPagamento,
        };
        await api.put(`operacao/acordo`, dataPago);
        setLoadingModal(false);
        onChildUpdate();

        setIsModalOpenComprovantes(false);
        message.success('Operação atualizada com sucesso!');
      } else {
        const valorPagoFloat = parseFloat(valorPago);
        console.log('valorPagoMulta', valorPagoMulta);
        const valorPagoMultaStr = String(valorPagoMulta);

        // Verifica se a vírgula existe na string e a substitui por um ponto, se presente
        const valorPagoMultaFloat = valorPagoMultaStr.includes(',') ? parseFloat(valorPagoMultaStr.replace(',', '.')) : parseFloat(valorPagoMultaStr);

        if (formaPagamento === '') {
          message.error('Não foi possível atualizar operação, selecione uma forma de pagamento!');
          setLoadingModal(false);
          return;
        }

        const dataPago = {
          status: novaEtapaChange,
          valorPago: valorPagoMultaFloat,
          valorPagoMulta: valorPagoFloat,
          comprovantesNovos: urlsFotosDocumento,
          formaPagamento,
        };

        await api.put(`/suboperation/${operacaoIdChange}`, dataPago);
        setLoadingModal(false);
        onChildUpdate();

        setIsModalOpenComprovantes(false);
        message.success('Operação atualizada com sucesso!');
      }
    } catch (error) {
      console.log('error', error.message);
      message.error('Não foi possível atualizar operação!');
      setLoadingModal(false);
    }
  };

  const handleFormaPagamentoChange = (value) => {
    setFormaPagamento(value);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const handleFiltrarClick = () => {
    setSearchBarVisible(!searchBarVisible);
  };

  const handleCancelarClick = () => {
    setSearchBarVisible(false);
    handleClearFilters();
  };

  const applyFilters = (filters) => {
    console.log('\n\n\nfilters', filters);
    let filteredData = [...data];

    // Verifica se o filtro de cliente está definido e não é nulo
    if (filters.cliente) {
      filteredData = filteredData.filter((item) => item.operacao?.cliente?.id === filters.cliente);
    }

    setTableData(filteredData);
    setVisibleData(filteredData.slice(0, 20));
  };

  const handleFilterChange = (filterKey, value) => {
    setActiveFilters({ ...activeFilters, [filterKey]: value });

    applyFilters({ ...activeFilters, [filterKey]: value });
  };

  const handleClearFilters = () => {
    setSelectedCliente(null);
    setSelectedMonth(null);
    setSelectedYear(null);
    setSelectedDay(null);
    setStatusFilter('todos');
    setActiveFilters({
      cliente: null,
      etapas: null,
      cpf: null,
      colaborador: null,
      operacao: null,
    });
    setTableData(data);
    setVisibleData(data.slice(0, 20));
  };

  const getTotalContracts = () => {
    return data.length;
  };

  const handleSwitchChange = (checked) => {
    setEnviarParaAcordo(checked);
  };

  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const getEtapaID = (etapa) => {
    return `etapa-${etapa}`; // Isso retornará algo como "etapa-inicio" baseado na etapa.
  };

  return (
    <div className={styles.container}>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='24' viewBox='0 0 23 24' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.2 3.32275C10.3525 3.32275 9.52129 3.55496 8.79652 3.99415L4.15884 6.80459C3.47686 7.21786 2.91295 7.8 2.52159 8.49478C2.13022 9.18957 1.92462 9.97352 1.92464 10.7709V16.607C1.92464 17.837 2.41325 19.0166 3.28298 19.8863C4.15272 20.7561 5.33233 21.2447 6.56232 21.2447H15.8377C17.0677 21.2447 18.2473 20.7561 19.117 19.8863C19.9867 19.0166 20.4754 17.837 20.4754 16.607V10.77C20.4752 9.97279 20.2695 9.18885 19.8781 8.49428C19.4868 7.79972 18.923 7.21776 18.2412 6.8046L13.6035 3.99416C12.8787 3.55497 12.0475 3.32275 11.2 3.32275ZM9.75792 5.58066C10.1928 5.31715 10.6915 5.17783 11.2 5.17783C11.7085 5.17783 12.2072 5.31715 12.6421 5.58066L17.2798 8.39109C17.6888 8.63899 18.0271 8.98817 18.2619 9.40492C18.4967 9.82159 18.6201 10.2918 18.6203 10.77V16.607C18.6203 17.345 18.3271 18.0528 17.8053 18.5746C17.2834 19.0964 16.5757 19.3896 15.8377 19.3896H14.9101V16.607C14.9101 15.623 14.5193 14.6793 13.8235 13.9835C13.1277 13.2878 12.184 12.8969 11.2 12.8969C10.216 12.8969 9.27232 13.2878 8.57653 13.9835C7.88074 14.6793 7.48985 15.623 7.48985 16.607V19.3896H6.56232C5.82432 19.3896 5.11656 19.0964 4.59472 18.5746C4.07288 18.0528 3.77971 17.345 3.77971 16.607V10.7709C3.7797 10.2925 3.90306 9.82209 4.13788 9.40522C4.3727 8.98835 4.71104 8.63906 5.12023 8.3911L9.75792 5.58066ZM12.5117 15.2953C12.8596 15.6432 13.0551 16.115 13.0551 16.607V19.3896H9.34493V16.607C9.34493 16.115 9.54037 15.6432 9.88826 15.2953C10.2362 14.9474 10.708 14.7519 11.2 14.7519C11.692 14.7519 12.1638 14.9474 12.5117 15.2953Z'
                    fill='#757575'
                  />
                </svg>
              </Link>
            ),
          },
          {
            title: (
              <>
                <span>Financeiro</span>
              </>
            ),
          },
          {
            title: 'Resumo Operacional',
          },
        ]}
      />
      <div className={styles.top}>
        <div className={styles.contractCounter}>
          <h1>
            Número de contratos: <span>{getTotalContracts()}</span>
          </h1>
        </div>
        <div className={styles.filtro}>
          <Button onClick={handleFiltrarClick}>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                d='M4.66679 3.99984H11.3335L7.99345 8.19984L4.66679 3.99984ZM2.83345 3.73984C4.18012 5.46651 6.66679 8.6665 6.66679 8.6665V12.6665C6.66679 13.0332 6.96679 13.3332 7.33345 13.3332H8.66679C9.03345 13.3332 9.33345 13.0332 9.33345 12.6665V8.6665C9.33345 8.6665 11.8135 5.46651 13.1601 3.73984C13.2365 3.64142 13.2838 3.52352 13.2964 3.39957C13.3091 3.27563 13.2868 3.15061 13.2319 3.03875C13.177 2.9269 13.0918 2.8327 12.986 2.76689C12.8802 2.70108 12.758 2.6663 12.6335 2.6665H3.36012C2.80679 2.6665 2.49345 3.29984 2.83345 3.73984Z'
                fill='#FFFFFF'
              />
            </svg>{' '}
            Filtrar
          </Button>
        </div>
      </div>
      {searchBarVisible && (
        <div className={styles.searchBar}>
          <div className={styles.containerSearchBar}>
            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23192 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23192C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Pesquisar cliente</p>
              </div>
              <Select
                id='cliente-select'
                showSearch
                allowClear
                placeholder='Selecione o cliente'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => {
                  setSelectedCliente(value);
                  handleFilterChange('cliente', value);
                }}
                value={selectedCliente}
                style={{ marginRight: 8 }}
              >
                {clientNames.map((cliente) => (
                  <Option key={cliente.id} value={cliente.id}>
                    {cliente.nome}
                  </Option>
                ))}
              </Select>
            </div>
            {/* <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por dia</p>
              </div>
              <Select value={selectedDay} onChange={(value) => setSelectedDay(value)} allowClear placeholder='Selecione o dia' style={{ marginRight: 8 }}>
                {[...Array(31)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </div> */}

            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por operação</p>
              </div>
              <Select value={statusFilter} onChange={handleStatusFilterChange}>
                <Option value='todos'> </Option>
                <Option value='pago'>Pago</Option>
                <Option value='Aberta'>Aberto</Option>
                <Option value='atrasada'>Atrasada</Option>
              </Select>
            </div>
            {/* <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por mês</p>
              </div>
              <Select value={selectedMonth} onChange={(value) => setSelectedMonth(value)} allowClear>
                <Option value={1}>Janeiro</Option>
                <Option value={2}>Fevereiro</Option>
                <Option value={3}>Março</Option>
                <Option value={4}>Abril</Option>
                <Option value={5}>Maio</Option>
                <Option value={6}>Junho</Option>
                <Option value={7}>Julho</Option>
                <Option value={8}>Agosto</Option>
                <Option value={9}>Setembro</Option>
                <Option value={10}>Outubro</Option>
                <Option value={11}>Novembro</Option>
                <Option value={12}>Dezembro</Option>
              </Select>
            </div> */}
            <div>
              <div className={styles.label}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <path
                    d='M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C19.8548 5.46237 19.9256 5.28553 19.9447 5.09961C19.9637 4.91368 19.9301 4.72615 19.8478 4.55837C19.7655 4.39059 19.6377 4.24929 19.479 4.15058C19.3203 4.05186 19.1371 3.99969 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z'
                    fill='#E8E8EC'
                  />
                </svg>
                <p>Filtrar por ano</p>
              </div>
              <Select value={selectedYear} onChange={(value) => setSelectedYear(value)} allowClear>
                {[...Array(10)].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <Divider />
          <div className={styles.buttons}>
            <Button onClick={handleClearFilters} className={styles.buttonLimpar}>
              Limpar Filtros
            </Button>
            <Button className={styles.buttonCancelar} onClick={handleCancelarClick}>
              Cancelar
            </Button>
          </div>
        </div>
      )}
      <div className={styles.containerFiltrosDatas}>
        <div style={{ display: 'flex', justifyContent: 'center', overflowX: 'auto', marginTop: '20px', width: '100%' }}>
          {months.map((month, index) => (
            <div
              key={index + 1}
              onClick={() => setSelectedMonth(index + 1)}
              style={{
                padding: '10px',
                margin: '5px',
                border: selectedMonth === index + 1 ? '2px solid #000' : '1px solid #ccc',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '100px',
              }}
            >
              {month}
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', overflowX: 'auto', marginTop: '20px', width: '100%' }}>
          {[...Array(31)].map((_, i) => (
            <div
              key={i + 1}
              onClick={() => setSelectedDay(i + 1)}
              style={{
                padding: '10px',
                margin: '5px',
                border: selectedDay === i + 1 ? '2px solid #000' : '1px solid #ccc',
                borderRadius: '5px',
                cursor: 'pointer',
                textAlign: 'center',
                width: '40px',
              }}
            >
              {i + 1}
            </div>
          ))}
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={visibleData}
        className={styles.table}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          setSortedInfo(sorter);
        }}
      />
      <Modal
        title='Detalhes da operação'
        open={isModalOpenComprovantes}
        onCancel={() => setIsModalOpenComprovantes(false)}
        footer={[
          <Button key='submit' type='primary' onClick={atualizarParcelaParaPago}>
            {loadingModal ? <LoadingOutlined /> : 'Finalizar Pagamento'}
          </Button>,
        ]}
        centered
      >
        <Form layout='vertical'>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label='Cliente'>
                <Input value={operacaoCompleta?.operacao?.cliente?.nome} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Valor do Empréstimo' className={styles.inputEstilizadoDisable}>
                <CurrencyInput
                  id='valor-emprestimo-input'
                  name='valorEmprestimo'
                  className={styles.valueInput}
                  value={valorEmprestimo.toFixed(2)}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  prefix='R$ '
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Valor da Parcela' className={styles.inputEstilizadoDisable}>
                <CurrencyInput id='valor-parcela-input' name='valorParcela' className={styles.valueInput} value={valorParcela} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} prefix='R$ ' disabled style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              {multaAtiva && (
                <Form.Item label='Valor da Multa' className={styles.inputEstilizadoDisable}>
                  <CurrencyInput id='valor-multa-input' name='valorMulta' className={styles.valueInput} value={valorMulta} intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} decimalsLimit={2} prefix='R$ ' disabled style={{ width: '100%' }} />
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item label='Saldo Restante Total' className={styles.inputEstilizadoDisable}>
                <CurrencyInput
                  id='saldo-restante-total-input'
                  name='saldoRestanteTotal'
                  className={styles.valueInput}
                  value={(valorTotalOperacao + valorMulta).toFixed(2)}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  prefix='R$ '
                  disabled
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Total a pagar' className={styles.inputEstilizadoDisable}>
                <CurrencyInput
                  id='valor-total-input'
                  name='valorTotal'
                  className={styles.valueInput}
                  value={valorTotal.toFixed(2)}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  prefix='R$ '
                  disabled
                  style={{ width: '100%' }}
                  onValueChange={(value) => setValorPago(value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item label='Valor Total Recebido' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-total-input'
                  name='valorTotalInput'
                  className={styles.valueInput}
                  placeholder='Digite o valor total'
                  value={valorTotalInput}
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  onValueChange={(value) => splitPayment(value)} // Use o valor diretamente
                  prefix='R$ '
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Parcela' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-pago-input'
                  name='valorPago'
                  className={styles.valueInput}
                  placeholder='Digite o valor da parcela'
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  value={valorPagoMulta} // Valor atual do estado
                  onValueChange={(value) => setValorPagoMulta(value)} // Remova o parseFloat
                  prefix='R$ '
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label='Valor Multa' className={styles.inputEstilizado}>
                <CurrencyInput
                  id='valor-pagoMulta-input'
                  name='valorPagoMulta'
                  className={styles.valueInput}
                  placeholder='Digite o valor da multa'
                  intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                  decimalsLimit={2}
                  value={valorPago} // Valor atual do estado
                  onValueChange={(value) => setValorPago(value)} // Remova o parseFloat
                  prefix='R$ '
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label='Forma de Pagamento'>
            <Select value={formaPagamento} onChange={handleFormaPagamentoChange}>
              <Option value='' disabled hidden>
                Selecione a forma de pagamento
              </Option>
              <Option value='dinheiro'>Dinheiro</Option>
              <Option value='pix'>Pix</Option>
              <Option value='cartao_credito'>Cartão de Crédito</Option>
              <Option value='cartao_debito'>Cartão de Débito</Option>
              <Option value='outro'>Outros</Option>
            </Select>
          </Form.Item>
          <Col span={24}>
            <Form.Item label='Colaborador'>
              <Input value={operacaoCompleta?.operacao?.usuario?.nome} disabled />
            </Form.Item>
          </Col>
          <Form.Item>
            <div className={styles.containerSwitch}>
              <h3>Adicionar contrato em acordos</h3>
              <Switch checked={enviarParaAcordo} onChange={handleSwitchChange} />
            </div>
          </Form.Item>
          {formaPagamento === 'outro' && (
            <Form.Item label='Outro Tipo de Pagamento' style={{ marginTop: '10px' }}>
              <Input value={outroPagamento} onChange={(e) => setOutroPagamento(e.target.value)} placeholder='Digite o tipo de pagamento' style={{ width: '100%' }} />
            </Form.Item>
          )}
        </Form>
        <hr />
        <Upload customRequest={handleUpload} listType='picture-card' fileList={comprovanteFileList} onPreview={handlePreview} onChange={handleComprovanteChange}>
          {comprovanteFileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Modal>

      <Modal
        title='Parcelas'
        open={isModalVerclienteOpen}
        onOk={() => setIsModalVerClienteOpen(false)}
        onCancel={() => setIsModalVerClienteOpen(false)}
        width={900} // Ajuste conforme necessário
      >
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Número Parcela</th>
                <th>Valor Pago</th>
                <th>Valor Parcela</th>
                <th>Restante a Pagar</th>
                <th>Status Pagamento</th>
                <th>Confirmação Pagamento</th>
                <th>Data Vencimento</th>
                {/* <th>Comprovantes</th> */}
              </tr>
            </thead>
            <tbody>
              {modalVercliente && modalVercliente.length > 0 ? (
                modalVercliente.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className={styles[getEtapaID(row.status)]}>
                        <p>{row.status === 'Pago' ? 'Pago' : 'Aberto'}</p>
                      </div>
                    </td>
                    <td>{row.numeroParcela}</td>
                    <td>{formatarComoMoeda(row.valorPago)}</td>
                    <td>{formatarComoMoeda(row.valorParcela)}</td>
                    <td>{formatarComoMoeda(row.saldoRestante)}</td>
                    <td>{row.statusPagamentoAgente === 'Pago' ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não Pago</p>}</td>
                    <td>{row.confirmacaoAnalista ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não Pago</p>}</td>
                    <td>{formatarData(row.dataVencimento)}</td>
                    {/* <td>
                      <Button onClick={() => verComprovantes(row.comprovantesPagamento)}>Comprovantes</Button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='9'>Nenhuma parcela encontrada.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal
        title='Enviar Mensagem'
        open={modalMensagem}
        onCancel={() => setModalMensagem(false)}
        footer={[
          <Button key='cancel' onClick={() => setModalMensagem(false)}>
            Cancelar
          </Button>,
          <Button key='submit' type='primary' onClick={enviarMensagem}>
            Enviar
          </Button>,
        ]}
      >
        <div style={{ marginBottom: '16px' }}>
          <label>Mensagem a ser enviada:</label>
          <Input.TextArea value={mensagem} onChange={(e) => setMensagem(e.target.value)} placeholder='Digite a mensagem aqui' rows={4} />
        </div>
        <div>
          <label>Número de telefone:</label>
          <Input value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder='Digite o número de telefone' />
        </div>
      </Modal>
    </div>
  );
};

export default TableColabores;
