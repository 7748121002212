import React, { useState, useMemo } from 'react';
import { Dropdown, Menu, Modal, Pagination, Select, Button, message, Upload, Image } from 'antd';
import styles from './style.module.scss';
import { FaRegEye } from 'react-icons/fa';
import { api } from '../../services/apiClient';

import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

const { Option } = Select; // Importação do Option

const baseURL = 'https://credx-bucket.s3.amazonaws.com/';

const ModalParcelas = ({ isModalOpen, setIsModalOpen, modalContent, getEtapaID, atualizarEtapaOperacao, formatarComoMoeda, formatarData, isModalOpenComprovantes, setIsModalOpenComprovantes, uploadProps }) => {
  return (
    <>
      <Modal
        title='Parcelas'
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={900} // Ajuste conforme necessário
      >
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Numero Parcela</th>
                <th>Valor Pago</th>
                <th>Valor parcela</th>
                <th>Restante a pagar</th>
                <th>Status Pagamento</th>
                <th>Confirmação pagamento</th>
                <th>Data vencimento</th>
                <th>Comprovantes</th>
              </tr>
            </thead>
            <tbody>
              {modalContent &&
                modalContent.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className={[getEtapaID(row['status'])]} id='selectTable'>
                        <Select defaultValue={row['status']} onChange={(value) => atualizarEtapaOperacao(row['id'], value, false)}>
                          <Option value='parcial'>Parcial</Option>
                          <Option value='aberto'>Aberta</Option>
                          <Option value='renegociar'>Renegociar</Option>
                          <Option value='pendente'>Pendente</Option>
                          <Option value='atrasado'>Atrasado</Option>
                          <Option value='finalizado'>Pago</Option>
                          {/* Adicione mais opções conforme necessário */}
                        </Select>
                      </div>
                    </td>
                    <td>{row['numeroParcela']}</td>
                    <td>{formatarComoMoeda(row['valorPago'])}</td>
                    <td>{formatarComoMoeda(row['valorParcela'])}</td>
                    <td>{formatarComoMoeda(row['saldoRestante'])}</td>
                    <td>{row['statusPagamentoAgente'] === 'Pago' ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não pago</p>}</td>
                    <td>{row['confirmacaoAnalista'] ? <p className={styles.pagoValue}>Pago</p> : <p className={styles.naoPagoValue}>Não pago</p>}</td>

                    <td>{formatarData(row['dataVencimento'])}</td>
                    <td>
                      <Button>Comprovantes</Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ModalParcelas;
