import React, { useEffect, useState } from 'react';
import { Card, Button, Popover } from 'antd'; // Importe o Modal do antd
import styles from './style.module.scss';
import { MdOutlineManageSearch, MdOutlineCancel } from 'react-icons/md';

import { FaRegEye, FaCheckCircle, FaMoneyBill } from 'react-icons/fa';

const RowAprovacoes = ({ operacao, handleRecusar, handleAceitar, openModalPix, formatarValor, handleVisualizarCliente, handleBlackList, handleAprovar }) => {
  const [isVisiblePopover, setIsVisiblePopover] = useState(false);
  const [isVisiblePopoverRefuse, setIsVisiblePopoverRefuse] = useState(false);

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.containerCardOperacao}>
          <div className={styles.containerInfos}>
            <div className={styles.containerNameCard}>
              <MdOutlineManageSearch
                style={{
                  fontSize: 24,
                }}
              />
              <p>{operacao?.cliente.nome}</p>
            </div>
            <div className={styles.containerNameCard}>
              <FaMoneyBill
                style={{
                  fontSize: 24,
                }}
              />
              <p>{formatarValor(operacao?.valorTotal)}</p>
            </div>
          </div>
          <div className={styles.containerButtons}>
          {operacao?.etapa === 'analise' && (
              <>
                <Button type='danger' className={styles.botaoVer} onClick={() => handleVisualizarCliente(operacao)}>
                  <FaRegEye
                    style={{
                      fontSize: 24,
                    }}
                  />
                  Ver
                </Button>
              </>
            )}
            {operacao?.etapa === 'aprovado' && (
              <>
                <Button type='danger' className={styles.botaoVer} onClick={() => handleVisualizarCliente(operacao)}>
                  <FaRegEye
                    style={{
                      fontSize: 24,
                    }}
                  />
                  Ver
                </Button>
              </>
            )}
            {operacao?.etapa === 'aberto' && (
              <>
                <Button type='danger' className={styles.botaoVer} onClick={() => handleVisualizarCliente(operacao)}>
                  <FaRegEye
                    style={{
                      fontSize: 24,
                    }}
                  />
                  Ver
                </Button>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RowAprovacoes;
