import React from 'react';
import { Table, Button, Tag } from 'antd';
import { FaRegEye } from 'react-icons/fa';
import styles from './style.module.scss';

const TableAnalise = ({ data, onVisualizarCliente }) => {
  const columns = [
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => (
        <Button disabled={record.statusPagamento === 'pago' ? true : false} onClick={() => onVisualizarCliente(record)} type='primary' className={styles.buttonPagar} size='small'>
          {record.statusPagamento === 'pago' ? 'Pago' : 'Analisar'}
        </Button>
      ),
    },
    {
      title: 'Tipo de Operação',
      dataIndex: 'tipoOperacao',
      key: 'tipoOperacao',
    },
    {
      title: 'Cliente',
      dataIndex: ['cliente', 'nome'],
      key: 'clienteNome',
    },
    {
      title: 'Nº de Parcelas',
      dataIndex: 'numeroParcelas',
      key: 'numeroParcelas',
    },
    {
      title: 'Colaborador',
      dataIndex: ['usuario', 'nome'],
      key: 'usuarioNome',
    },
    {
      title: 'Valor do Empréstimo',
      dataIndex: 'valorEmprestimo',
      key: 'valorEmprestimo',
      render: (valor) => `R$ ${valor.toFixed(2)}`,
    },
    {
      title: 'Data de Empréstimo',
      dataIndex: 'dataEmprestimo',
      key: 'dataEmprestimo',
      render: (text) => new Date(text).toLocaleDateString('pt-BR'),
    },
    {
      title: 'Juros',
      dataIndex: 'juros',
      key: 'juros',
      render: (text) => `${text}%`,
    },

    {
      title: 'Valor Total',
      dataIndex: 'valorTotal',
      key: 'valorTotal',
      render: (valor) => `R$ ${valor.toFixed(2)}`,
    },
  ];

  return (
    <div className={styles.container}>
      <Table columns={columns} bordered dataSource={data} className={styles.table} />
    </div>
  );
};

export default TableAnalise;
