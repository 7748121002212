import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout, Button, ConfigProvider } from 'antd';
import SidebarOfficial from './Components/Sidebar';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, useAuth } from './contexts/AuthContext';

import Configuration from './Pages/Configs';

import Client from './Pages/Cadastro/Clientes';
import Recebiveis from './Pages/Cadastro/Recebiveis';
import Recebimentos from './Pages/Cadastro/Recebimentos';
import Contratos from './Pages/Cadastro/Contratos';
import Pagamentos from './Pages/Cadastro/Pagamentos';
import Listas from './Pages/Cadastro/Listas';
import Operacao from './Pages/Cadastro/Operacao';
import Usuarios from './Pages/Cadastro/Usuarios';
import MetasCadastro from './Pages/Cadastro/Metas';

import Clientes from './Pages/Bases/Clientes';
import ContratosBase from './Pages/Bases/Contratos';
import Metas from './Pages/Bases/Metas';
import Blacklist from './Pages/Bases/Blacklist';
import UsuariosBase from './Pages/Bases/Usuarios';

import ComColaboradores from './Pages/Relatorios/ComColaboradores';
import Areasocio from './Pages/Relatorios/Areasocio';
import FluxoCaixaRel from './Pages/Relatorios/FluxoCaixaRel';
import Saldo from './Pages/Relatorios/Saldo';
import Justificativas from './Pages/Relatorios/Justificativas';
import ContaCorrente from './Pages/Relatorios/ContaCorrente';

import Acordos from './Pages/Financeiro/Acordos'
import Resultados from './Pages/Financeiro/Resultados';
import ContratosGestao from './Pages/Financeiro/ContratosGestao';
import ContratosColaboradores from './Pages/Financeiro/ContratosColaboradores';
import FluxoCaixa from './Pages/Financeiro/FluxoCaixa';
import ptBR from 'antd/lib/locale/pt_BR';
import Analise from './Pages/Operacao/Análise';
import Aprovado from './Pages/Operacao/Aprovado';
import AConfirmar from './Pages/Operacao/AConfirmar';
import Chat from './Pages/Chat';
import Consultas from './Pages/Consultas';
import FluxoDiario from './Pages/Financeiro/fluxoDIario';

const { Header, Footer, Sider, Content } = Layout;

const customLocale = {
  ...ptBR,
  Pagination: {
    ...ptBR.Pagination,
    items_per_page: '/ página',
    colorPrimary: '#AA965D',
  },
};

const tokenConfig = {
  token: {
    colorPrimary: '#141D45',
    colorPrimaryBorder: 'rgba(20, 29, 69, 0.53)',
    colorPrimaryHover: '#3E4666',
  },
};

const App = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Router>
      <AuthProvider>
        {isAuthenticated() ? (
          <ConfigProvider locale={customLocale} theme={tokenConfig}>
            <Layout style={{ minHeight: '100vh' }}>
              <SidebarOfficial collapsed={collapsed} toggleCollapsed={toggleCollapsed} userLevel={user.tipoUsuario} />

              <Layout>
                <Content style={{ margin: '2rem 16px' }}>
                  <Routes>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='/analise' element={<Analise />} />
                    <Route path='/aprovado' element={<Aprovado />} />
                    <Route path='/aconfirmar' element={<AConfirmar />} />
                    <Route path='/configuracoes' element={<Configuration />} />

                    <Route path='/cadastro/usuarios' element={<Usuarios />} />
                    <Route path='/cadastro/clientes' element={<Client />} />
                    <Route path='/cadastro/recebiveis' element={<Recebiveis />} />
                    <Route path='/cadastro/recebimentos' element={<Recebimentos />} />
                    <Route path='/cadastro/pagamentos' element={<Pagamentos />} />
                    <Route path='/cadastro/listas' element={<Listas />} />
                    <Route path='/cadastro/contratos/:cpf?' element={<Operacao />} />
                    <Route path='/cadastro/metas' element={<MetasCadastro />} />

                    <Route path='/bases/clientes' element={<Clientes />} />
                    <Route path='/bases/contratos' element={<ContratosBase />} />
                    <Route path='/bases/metas' element={<Metas />} />
                    <Route path='/bases/blacklist' element={<Blacklist />} />
                    <Route path='/bases/usuarios' element={<UsuariosBase />} />

                    <Route path='/relatorios/comcolaboradores' element={<ComColaboradores />} />
                    <Route path='/relatorios/areasocio' element={<Areasocio />} />
                    <Route path='/relatorios/fluxocaixarel' element={<FluxoCaixaRel />} />
                    <Route path='/relatorios/saldo' element={<Saldo />} />
                    <Route path='/relatorios/justificativas' element={<Justificativas />} />
                    <Route path='/relatorios/contacorrente' element={<ContaCorrente />} />

                    <Route path='/financeiro/acordos' element={<Acordos />} />
                    <Route path='/financeiro/resultados' element={<Resultados />} />
                    <Route path='/financeiro/contratogestao' element={<ContratosGestao />} />
                    <Route path='/financeiro/contratocolaboradores' element={<ContratosColaboradores />} />
                    <Route path='/financeiro/fluxodecaixa' element={<FluxoCaixa />} />
                    <Route path='/financeiro/fluxodiario' element={<FluxoDiario />} />

                    <Route path='/chat' element={<Chat />} />
                    <Route path='/consultas' element={<Consultas />} />
                  </Routes>

                  <ToastContainer />
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>Seu Footer</Footer> */}
              </Layout>
            </Layout>
          </ConfigProvider>
        ) : (
          <Login />
        )}
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;
