import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Select } from 'antd';
import { api } from '../../services/apiClient';

const EditFluxoCaixa = ({ id, onClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/fluxo-de-caixa/${id}`);
        form.setFieldsValue(response.data);
      } catch (error) {
        message.error('Erro ao carregar dados do fluxo de caixa.');
      }
    };
    fetchData();
  }, [id]);

  const onFinish = async (values) => {
    try {
      
      await api.put(`/fluxo-de-caixa/${id}`, values);
      message.success('Fluxo de caixa atualizado com sucesso!');
      onClose();
    } catch (error) {
      message.error('Erro ao atualizar fluxo de caixa.');
    }
  };

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Form.Item name='tipo' label='Nome' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='subtipo' label='Descrição'>
        <Input />
      </Form.Item>
      <Form.Item name='valor' label='Valor' rules={[{ required: true }]}>
      <Input
          style={{ width: '100%' }}
          placeholder='Informe o valor'
          addonBefore='R$'
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          }
          parser={(value) => value.replace(/\R\$\s?|(,*)/g, '').replace('.', ',')}
        />
      </Form.Item>
      <Form.Item name='categoria' label='Categoria' rules={[{ required: true }]}>
      <Select placeholder='Selecione uma categoria'>
          <Option value='Empréstimos'>Empréstimos</Option>
          <Option value='Pagamento Comissão'>Pagamento Comissão</Option>
          <Option value='Retirada sócio'>Retirada sócio</Option>
          <Option value='Despesas bancárias'>Despesas bancárias</Option>
          <Option value='Outras saídas'>Outras saídas</Option>
          <Option value='Devolução de aporte'>Devolução de aporte</Option>
          <Option value='Salário'>Salário</Option>
        </Select>
      </Form.Item>
      {/* <Form.Item name='operacaoId' label='Operação ID'>
        <Input type='number' />
      </Form.Item> */}
      <Form.Item>
        <Button type='primary' htmlType='submit' style={{marginTop: '2rem'}} >
          Atualizar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditFluxoCaixa;
