import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { api } from '../../../services/apiClient';
import TableContratos from '../../../Components/TableContratos'

const Contratos = () => {
  // const [contratosData, setContratosData] = useState([])

  // const fetchData = async () => {
  //   try {
  //     const response = await api.get(`/operation`);
      
  //     setContratosData(response.data);
  //     console.log('response.data', response.data)
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {

  //   fetchData();
  // }, []);

  return (
    <TableContratos />
  );
};

export default Contratos;
